import React, { useEffect, useState, useRef } from "react";
import DataGrid, { Column, Lookup, Button as ButtonColumn, Sorting, Scrolling, Pager,Paging } from "devextreme-react/data-grid";
import Container from "../../../components/container";
import { forexOptions } from "../../../utils/lookupstore";
import SalesCashSalesForm from "./form";
import Listing from "../../../components/listing/newListing";
import utils from "../../../utils/common";
import DropDownButton from "devextreme-react/drop-down-button";
import Transform from "../transform/Transform";
import EInvoiceMain from "../../../components/e-invoice/EInvoiceMain";

export default function SalesCashSalesListing() {
	const [currentEditID, setCurrentEditID] = useState(null);
	const [currentDetailID, setCurrentDetailID] = useState(null);
	const [forexList, setForexList] = useState([]);
	const [dropDownRead, setDropDownRead] = useState(false);
	const [transformTitle, setTransformTitle] = useState(null);
	const [transferData, setTransferData] = useState(null);
	const transformRef = useRef(null);

	const [invType, setInvType] = useState(null);

	const apiURL = `/api/SalesCashSales`;
	const listingAPIURL = `/api/SalesCashSales/list`;

	useEffect(() => {
		// Promise.allSettled([customerOptions.store.load(), salesmanOptions.store.load(), forexOptions.store.load(), taxCodeOptions.store.load(),
		//     transactionTypeOptions.store.load(), glChartAccountOptions.store.load(), salesTypeOptions.store.load(), stockOptions.store.load(),
		//     projectOptions.store.load(), userOptions.store.load(), gstRateOptions.store.load(), uomOptions.store.load(),
		//     locationOptions.store.load(), chartAccountPayment.store.load() ]).then((lists) => {
		//     setDropDownList({
		//         customerList: lists[0].value.data, salesmanList: lists[1].value.data, forexList: lists[2].value.data, taxCodeList: lists[3].value.data,
		//         transactionTypeList: lists[4].value.data, chartAccountList: lists[5].value.data, salesTypeList: lists[6].value.data,
		//         stockList : lists[7].value.data, projectList: lists[8].value.data, userList: lists[9].value.data, gstRateList: lists[10].value.data,
		//         uomList: lists[11].value.data, locationList: lists[12].value.data, paymentMethodList: lists[13].value.data.items
		//     });
		// });

		if (dropDownRead !== true) {
			forexOptions.store.load().then((list) => {
				setForexList(list.data);
			});
			setDropDownRead(true);
		}

	
	}, [dropDownRead]);

	function handleEdit(e) {
		const id = e.row.key;
		setCurrentEditID(id);
	}

	function handleEInvoice(e) {
		setInvType('01');
		setCurrentDetailID(e.row.data.DocumentNo);
	}

	const onItemClick = (e) => {
		const { action } = e.itemData;
		setTransformTitle(action);
		utils.transformOpen(transformRef);
	};

	const transferFormData = (data) => {
        setCurrentEditID("new");
        setTransferData(data);
    };

	const closePopup = (value) => {
		setCurrentEditID(value);
		setCurrentDetailID(value);
		setTransferData(null);
	};



	return (
		<Container>
			<div id='normal-listing'>
				<Listing
					className='sales-listing-datagrid'
					apiURL={apiURL}
					listingURL={listingAPIURL}
					sortColumn={"DocumentNo"}
					listingTitle={"Cash Sales"}
					storageName={"SalesCashSalesListingColumnWidth"}
					onAddClick={setCurrentEditID}
					einvoiceshow={true}
					customizedAddButton={
						<DropDownButton
							className='customized-listing-add-btn sales-listing-btn'
							splitButton={true}
							useSelectMode={false}
							icon='add'
							text='Add'
							items={[
								{ id: 1, action: "Transfer from Quotation" },
								{ id: 2, action: "Transfer from Sales Order" },
								{ id: 3, action: "Transfer from Delivery Order" }
							]}
							displayExpr='action'
							keyExpr='id'
							width={"100px"}
							dropDownOptions={{ width: "200px" }}
							onItemClick={onItemClick}
							onButtonClick={() => {
								setCurrentEditID("new");
							}}
						/>
					}
					handleEInvoice={handleEInvoice}
					handleEdit={handleEdit}
					>
					<Column dataField='DocumentNo' />

					<Column dataField='DocumentDate' format='dd/MM/yyyy' dataType={"date"} />

					<Column dataField='CustomerCode' />

					<Column dataField='CustomerName' />

					<Column dataField="ForexCode" caption="Forex"/>

					<Column dataField='MainForexAmount' caption={"Cash Sales Amount"} format={{ type: "fixedPoint", precision: 2 }} />

					<Column dataField='MainLocalAmount' caption={"Local Amount"} format={{ type: "fixedPoint", precision: 2 }} />

					<Column dataField='OutstandingAmount' format={{ type: "fixedPoint", precision: 2 }} />
				</Listing>
			</div>

			<SalesCashSalesForm 
				formID={currentEditID} 
				closePopup={closePopup} 
				apiURL={apiURL} 
				title={"Cash Sales Entry"} 
				transferData={transferData}
				clearTransfer={setTransferData}
			/>

			<EInvoiceMain 
				formID={currentDetailID} 
				closePopup={closePopup} 
				apiURL={apiURL} 
				title={"E-Invoice Main"} 
				  invoiceType={invType}
				transferData={transferData}
				clearTransfer={setTransferData}
			/>

			<Transform 
                ref={transformRef} 
                title={transformTitle} 
                apiPath={{
					"Transfer from Quotation": {
						FullDocumentListing: "api/SalesTransfer/QuotationFullDocument",
						PartialDocumentListing: "api/SalesTransfer/QuotationPartial",
						FullDocumentTransfer: "api/SalesTransfer/QuotationFullTransfer",
						PartialDocumentTransfer:  "api/SalesTransfer/QuotationPartialTransfer",
						FilterPath: "api/SalesTransfer/QuotationFilter"
					},
					"Transfer from Sales Order": {
						FullDocumentListing: "api/SalesTransfer/OrderFullDocument",
						PartialDocumentListing: "api/SalesTransfer/OrderPartial",
						FullDocumentTransfer: "api/SalesTransfer/OrderFullTransfer",
						PartialDocumentTransfer:  "api/SalesTransfer/OrderPartialTransfer",
						FilterPath: "api/SalesTransfer/OrderFilter"
					},
                    "Transfer from Delivery Order": {
                        FullDocumentListing: "api/SalesTransfer/DeliveryOrderFullDocument",
						PartialDocumentListing: "api/SalesTransfer/DeliveryOrderPartial",
						FullDocumentTransfer: "api/SalesTransfer/DeliveryOrderFullTransfer",
						PartialDocumentTransfer:  "api/SalesTransfer/DeliveryOrderPartialTransfer",
						FilterPath: "api/SalesTransfer/DeliveryOrderFilter"						
					},
				}}
				transferData={transferFormData}
            />
		</Container>
	);
}
