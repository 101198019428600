import React, { useEffect, useState, } from 'react';
import { Column, Lookup, } from 'devextreme-react/data-grid';
import { forexOptions } from '../../../utils/lookupstore';
import Container from '../../../components/container';
import APDebitNoteForm from './form';
import PurchaseDebitNoteForm from '../../Purchase-Module/debit-note/form';
import Listing from '../../../components/listing/newListing';
import utils from '../../../utils/common';
import baseapi from '../../../api/baseapi';
import EInvoiceMain from "../../../components/e-invoice/EInvoiceMain";

export default function APDebitNoteListing() {
    const [invType, setInvType] = useState(null);
    const [currentDetailID, setCurrentDetailID] = useState(null);
    const apiURL = `/api/APDebitNote`;
    const [currentEditID, setCurrentEditID] = useState(null);
    const [currentEditID2, setCurrentEditID2] = useState(null);
    const [dropDownList, setDropDownList] = useState({
        forexList: []
    });

    function handleEInvoice(e) {
        setInvType('13');
        setCurrentDetailID(e.row.data.DocumentNo);
    }

    useEffect(() => {
        Promise.allSettled([forexOptions.store.load()]).then((lists) => {
            setDropDownList({
                forexList: lists[0].value.data,
            })
        });
    }, []);

    function handleEdit(e) {
        const rowData = e.row.data;
        baseapi.httpget("/api/APDebitNote/CheckPurchaseCopied", { docNo: rowData.DocumentNo }).then((response) => {
            const { data } = response;
            if (!utils.isNullOrEmpty(data)) {
                if (data.isCopied) {
                    setCurrentEditID2(data.id);
                }
                else {
                    setCurrentEditID(rowData.id);
                }
            }
        });
    }

    const closePopup = (id) => {
        setCurrentEditID(id);
        setCurrentEditID2(id);
        setCurrentDetailID(id);
    }


    return <Container>
        <div id='normal-listing'>
            <Listing
                className="ap-listing-datagrid"
                apiURL={apiURL}
                listingURL="/api/APDebitNote/list"
                sortColumn={"DocumentNo"}
                listingTitle={"AP Debit Note"}
                storageName={"APDebitNoteListingColumnWidth"}
                einvoiceshow={true}
                onAddClick={setCurrentEditID}
                handleEdit={handleEdit}
                handleEInvoice={handleEInvoice}
            >

                <Column dataField="DocumentNo" />
                <Column dataField="DocumentDate" format="dd/MM/yyyy" dataType={"date"} />
                <Column dataField="SupplierCode" />
                <Column dataField="SupplierName" />
                <Column dataField="ForexCode" caption="Forex"/>
                <Column dataField="MainForexAmount" caption={"Amount"} format={{ type: "fixedPoint", precision: 2 }} />
                <Column dataField="MainLocalAmount" caption={"Local Amount"} format={{ type: "fixedPoint", precision: 2 }} />
                <Column dataField="OutstandingAmount" caption={"U/A Amount"} format={{ type: "fixedPoint", precision: 2 }} />
            </Listing>
        </div>

        <APDebitNoteForm
            formID={currentEditID}
            closePopup={closePopup}
            title={"A/P Debit Note"}
            apiURL={apiURL}
        />

        <EInvoiceMain
            formID={currentDetailID}
            closePopup={closePopup}
            apiURL={apiURL}
            title={"E-Invoice Main"}
            invoiceType={invType}

        />

        <PurchaseDebitNoteForm
            formID={currentEditID2}
            closePopup={closePopup}
            title={"Purchase Debit"}
            apiURL={`/api/PurchaseDebitNote`}
        />
    </Container>;
}