import React, { useState, useEffect } from "react";
import { Column, TotalItem, Summary } from "devextreme-react/data-grid";
import Listing from "../../components/listing/report-component/reportsListing";
import utils from "../../utils/common";
import { apstatementListing } from "../../utils/lookupstore";

export default function APStatementofAccountListing() {
	const listingTitle = "Supplier Statement Report";
	const reportName = "Supplier Statement - 6 Months";
	const [dropDownList, setDropDownList] = useState({
		reportList: []
	});

	const columns = [
		{ dataField: "DocNo", caption: "DocNo" },
		{ dataField: "Date", caption: "Date", dataType: "date", format: "dd/MM/yyyy" },
		{ dataField: "Remarks", caption: "Desc" },
		{ dataField: "Cheque", caption: "Cheque" },
		{ dataField: "Debit", caption: "Debit", format: { type: "fixedPoint", precision: 2 } },
		{ dataField: "Credit", caption: "Credit", format: { type: "fixedPoint", precision: 2 } },
		{ dataField: "RunningSum", caption: "Balance", format: { type: "fixedPoint", precision: 2 } },
		{ dataField: "Type", caption: "Type", width: "50" }
	];

	const summaryItems = [
		{
			key: "debitTotal",
			column: "Debit",
			summaryType: "sum",
			showInColumn: "DocNo",
			customizeText: () => {
				return "Total";
			}
		},
		{
			key: "debitCurrency",
			column: "Debit",
			summaryType: "sum",
			customizeText: (e) => {
				return utils.numberToCurrency(e.value);
			}
		},
		{
			key: "creditCurrency",
			column: "Credit",
			summaryType: "sum",
			customizeText: (e) => {
				return utils.numberToCurrency(e.value);
			}
		}
	];

	useEffect(() => {
		Promise.allSettled([apstatementListing.store.load()]).then((lists) => {
			setDropDownList({
				reportList: lists[0].value.data
			});
		});
	}, []);

	return (
		<div>
			<Listing
				disableDetails={true}
				className='ar-listing-datagrid'
				isStatement={true}
				isAR={false}
				keyDetail={"Code"}
				reportName={reportName}
				listingTitle={listingTitle}
				reportList={dropDownList.reportList}
				detailColumns={columns}
				storageName={"APStatementReportListingColumnWidth"}
				summaryDetail={summaryItems}>
				{/* <Paging defaultPageSize={15} /> */}
				<Column dataField='Code' />
				<Column dataField='Type' caption={"Supplier"} />
				<Column dataField='Area' />
				{/* <Column dataField='Salesman' /> */}
				<Column dataField='Forex' />
				<Column dataField='Balance' format={{ type: "fixedPoint", precision: 2 }} dataType={"number"} />
				<Summary>
					<TotalItem
						column='Balance'
						summaryType='sum'
						customizeText={(e) => {
							return "Total: " + utils.numberToCurrency(e.value);
						}}
					/>
				</Summary>
			</Listing>
		</div>
	);
}
