import React, { useEffect } from 'react';
import SettingListing from '../../components/listing/SettingListing';
import Container from '../../components/container';
import { Column } from 'devextreme-react/data-grid';
import utils from '../../utils/common';

export default function Forex(){
    return <Container>
        <div id='normal-listing'>
            <SettingListing
                listingTitle="Forex"
                apiURL="/api/Forex"
                listingURL="/api/Forex/list"
                sortColumn={"Code"}
                dateFilter={false}
                defaultSettingTemplate={false}
                storageName={"SettingForexListing"}
                customizedFormRender={[
                    {type: "asyncTextbox", dataField: "Code", asyncProperty: "code", required: true},
                    {type: "textbox", dataField: "Name", required: true},
                    {type: "numberbox", dataField: "LocalRate", caption: "Local Rate", config: {format: utils.LocalRateFormat}, required: true},
                    {type: "numberbox", dataField: "ForexRate", caption: "Forex Rate", config: {format: utils.LocalRateFormat}, required: true},
                    {type: "checkbox", dataField: "IsDefault", caption: "Is Default"},
                ]}
                className={"maintenance-listing-datagrid"}
            >
                <Column 
                    dataField="Code" 
                    dataType="string"
                    cellTemplate={(container, options) => {
                        // Render the code text
                        container.textContent = options.data.Code;
                        // If the Default field is true, append the word "default"
                        if (options.data.IsDefault) {
                            const defaultSpan = document.createElement("span");
                            defaultSpan.classList.add("default-option-label");
                            defaultSpan.textContent = "Default";
                            container.appendChild(defaultSpan);
                        }
                    }}
                />
                <Column dataField="Name" dataType="string"/>
                <Column dataField="ForexRate" dataType="string"/>
                <Column dataField="LocalRate" dataType="string"/>
                <Column visible={true} dataField="Active" caption="Active" width={100}/>
            </SettingListing>
        </div>
    </Container>
}