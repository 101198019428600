import React, { useEffect, useState, useRef } from 'react';
import { DropDownButton } from 'devextreme-react';
import { Column, Lookup,} from 'devextreme-react/data-grid';
import { forexOptions } from '../../../utils/lookupstore';
import Container from '../../../components/container';
import SalesInvoiceMainForm from './form';
import Listing from '../../../components/listing/newListing';
import utils from '../../../utils/common';
import Transform from '../transform/Transform';
import EInvoiceMain from "../../../components/e-invoice/EInvoiceMain";

export default function SalesInvoiceMainListing(){

    const apiURL = `/api/SalesInvoiceMain`;
    const [currentEditID, setCurrentEditID] = useState(null);

    const [dropDownList, setDropDownList] = useState({
        forexList: []
    });
    const transformRef = useRef(null);
	const [transformTitle, setTransformTitle] = useState(null);
    const [transferData, setTransferData] = useState(null);

    const [invType, setInvType] = useState(null);
    const [currentDetailID, setCurrentDetailID] = useState(null);

    useEffect(() => {
        Promise.allSettled([forexOptions.store.load()]).then((lists) => {
            setDropDownList({
                forexList: lists[0].value.data,
            })
        });
    }, []);

    function handleEdit(e) {
        const id = e ? e.row.data.id : 0;
        setCurrentEditID(id);
    }

    const onItemClick = (e) => {
		const { action } = e.itemData;
		setTransformTitle(action);
		utils.transformOpen(transformRef);
	};

    const transferFormData = (data) => {
        setCurrentEditID("new");
        setTransferData(data);
    };

    const closePopup = (value) => {
		setCurrentEditID(value);
		setTransferData(null);
		setCurrentDetailID(value);
	};

        
	function handleEInvoice(e) {
		setInvType('01');
		setCurrentDetailID(e.row.data.DocumentNo);
	}

    return <Container>
        <div id='normal-listing'>
            <Listing
                className="sales-listing-datagrid"
                apiURL={apiURL}
                einvoiceshow={true}
                handleEInvoice={handleEInvoice}
                listingURL="/api/SalesInvoiceMain/list"
                sortColumn={"DocumentDate"}
                sortOrder={"desc"}
                listingTitle={"Invoice Main"}
                storageName={"SalesInvoiceMainListingColumnWidth"}
                onAddClick={setCurrentEditID}
                // addButtonOnClick={() => {setLockForm(false);}}
                handleEdit={handleEdit}
                customizedAddButton={
                    <DropDownButton
                        className='customized-listing-add-btn sales-listing-btn'
                        splitButton={true}
                        useSelectMode={false}
                        icon='add'
                        text='Add'
                        items={[
                            { id: 1, action: "Transfer from Quotation" },
                            { id: 2, action: "Transfer from Sales Order" },
                            { id: 3, action: "Transfer from Delivery Order" }
                        ]}
                        displayExpr='action'
                        keyExpr='id'
                        width={"100px"}
                        dropDownOptions={{ width: "200px" }}
                        onItemClick={onItemClick}
                        onButtonClick={() => {
                            setCurrentEditID("new");
                        }}
                    />
                }
            >
                <Column dataField="DocumentDate" format="dd/MM/yyyy" dataType={"date"} />
                <Column dataField="DocumentNo" />
                <Column dataField="CustomerCode" />
                <Column dataField="CustomerName" />
                <Column dataField="ForexCode" caption="Forex"/>
                <Column dataField="MainForexAmount" caption={"Amount"} alignment={"right"} format={{ type: "fixedPoint", precision: 2}} />
                <Column dataField="MainLocalAmount" caption={"Local Amount"} format={{ type: "fixedPoint", precision: 2}} />
                <Column dataField="OutstandingAmount" allowFiltering={false} alignment={"right"} format={{ type: "fixedPoint", precision: 2}} />
            </Listing>
            </div>
            
            <SalesInvoiceMainForm 
                formID={currentEditID}
                closePopup={closePopup}
                apiURL={apiURL}
                title={"Invoice Entry"}
                transferData={transferData}
                clearTransfer={setTransferData}
            />

            
        <EInvoiceMain 
				formID={currentDetailID} 
				closePopup={closePopup} 
				apiURL={apiURL} 
				title={"E-Invoice Main"} 
				  invoiceType={invType}
			/>

            <Transform 
                ref={transformRef} 
                title={transformTitle} 
                apiPath={{
					"Transfer from Quotation": {
						FullDocumentListing: "api/SalesTransfer/QuotationFullDocument",
						PartialDocumentListing: "api/SalesTransfer/QuotationPartial",
						FullDocumentTransfer: "api/SalesTransfer/QuotationFullTransfer",
						PartialDocumentTransfer:  "api/SalesTransfer/QuotationPartialTransfer",
						FilterPath: "api/SalesTransfer/QuotationFilter"
					},
					"Transfer from Sales Order": {
						FullDocumentListing: "api/SalesTransfer/OrderFullDocument",
						PartialDocumentListing: "api/SalesTransfer/OrderPartial",
						FullDocumentTransfer: "api/SalesTransfer/OrderFullTransfer",
						PartialDocumentTransfer:  "api/SalesTransfer/OrderPartialTransfer",
						FilterPath: "api/SalesTransfer/OrderFilter"
					},
                    "Transfer from Delivery Order": {
                        FullDocumentListing: "api/SalesTransfer/DeliveryOrderFullDocument",
						PartialDocumentListing: "api/SalesTransfer/DeliveryOrderPartial",
						FullDocumentTransfer: "api/SalesTransfer/DeliveryOrderFullTransfer",
						PartialDocumentTransfer:  "api/SalesTransfer/DeliveryOrderPartialTransfer",
						FilterPath: "api/SalesTransfer/DeliveryOrderFilter"						
					},
				}}
                transferData={transferFormData}
            />
        </Container>;
}