import PopupForm from "../../components/popup-form/PopupFormV2";
import { useEffect, useState, useRef } from "react";
import baseapi from "../../api/baseapi";
import { CheckBox } from "devextreme-react";
import utils, { loading, closeLoading } from "../../utils/common";
import CustomerForm from "./ContraForms/CustomerForm";
import SupplierForm from "./ContraForms/SupplierForm";
import { arContra, apContra } from "../../utils/lookupstore";

export default function ContraForm(props) {
	const [formValue, setFormValue] = useState({});
	const [isEditing, setIsEditing] = useState(false);
	const [startValidation, setStartValidation] = useState(0);
	const [formType, setFormType] = useState(props.formType);
	const [oriFormType, setOriFormType] = useState(props.formType);
	const [docNo, setDocNo] = useState(null);
	const docNoOriginal = props.documentNo;

	const tempForm = useRef({});
	const formID = useRef(null);
	const formRef = useRef(null);
	const formRead = useRef(false);
	const contraFormRef = useRef(null);
	const [openedBefore, setOpenedBefore] = useState(props.openedBefore);

	useEffect(() => {
		if (props.formID !== null) {
			formID.current = props.formID;
			utils.popupFormOpen(formRef);

			if (formID.current !== "<NEW>") {
				setIsEditing(true);
			} else {
				setIsEditing(false);
			}
			setFormType(props.formType);
			if (docNoOriginal !== null) {
				setDocNo(docNoOriginal);
			}
		}
	}, [props.formID]);

	useEffect(() => {
		if (props.onDocumentNoChanged !== undefined) {
			props.onDocumentNoChanged({ value: docNo });
		}
	}, [docNo]);

	useEffect(() => {
		if (props.onOpenedBefore !== undefined) {
			props.onOpenedBefore({ value: openedBefore });
		}
	}, [openedBefore]);

	useEffect(() => {
		setOriFormType(props.formType);
	}, [props.formType]);

	const clearForm = () => {
		props.closePopup(null);
		contraFormRef.current.setValue({});
		contraFormRef.current.setValidation(0);
		formRead.current = false;
		formID.current = null;
		setDocNo(null);
		setOpenedBefore(false);
		setOriFormType(props.formType);
	};

	function onInitialized(e) {
		const loadingMessage = formID.current === "<NEW>" ? `Loading New ${formType} Contra...` : `Loading Existing ${formType} Contra...`;
		loading(loadingMessage);

		const apiEndpoint = formType === "Customer" ? "/api/ARContraMain/Get" : "/api/APContraMain/Get";

		baseapi.httpget(apiEndpoint, { id: formID.current || "" }).then((response) => {
			const { data } = response;
			utils.popupFormAfterInitialization(formRef, props, data);

			if(contraFormRef.current){
				//Set the default value to disable forex if it is RM
				if (!utils.isNullOrEmpty(data.defaultValues)) {
					contraFormRef.current.setDefaultValues(data.defaultValues);
				}
			}
			// console.log(data);
			// console.log(Object.keys(formValue));

			// if (docNo === null && formID.current === "<NEW>") {
			// 	// console.log("In Here");
			// 	// data.model.Parent.DocumentNo = docNo;
			// 	// data.model.Parent.NextDocNo = docNo;
			// 	// data.model.Parent.DocumentDate = formValue["DocumentDate"];
			// } else
			if (docNo !== null && formID.current === "<NEW>") {
				// console.log("In Here 2");
				data.model.Parent.DocumentNo = docNo;
				// data.model.Parent.NextDocNo = docNo;
				if (props.documentDate !== null) {
					data.model.Parent.DocumentDate = props.documentDate;
				}
			}

			tempForm.current = data.model.Parent;
			// console.log(tempForm.current);
			// setOriFormType(props.formType);

			contraFormRef.current.setValue(tempForm.current);
			contraFormRef.current.editingForm(formID.current !== "<NEW>");
			closeLoading();
			formRead.current = true;
		});
	}

	const formActionButton = () => {};

	const handleSubmit = (stay = false) => {
		// console.log("Opened Before: ", openedBefore);
		const submittedForm = { Parent: formValue };
		const offsetDetails = contraFormRef.current.getOffsetValue().filter((row) => row?.OffsetAmount > 0);
		const saveUrl = formType === "Customer" ? "/api/ARContraMain/save" : "/api/APContraMain/save";
		const offsetSaveUrl = formType === "Customer" ? "/api/ARContraOffset/save" : "/api/APContraOffset/save";
		var openNext = openedBefore ? false : true;

		// console.log("ID", formID.current);

		if (formID.current !== "<NEW>") {
			openNext = !openNext;
		}

		// console.log(formValue);
		// console.log("Opened After: ", openNext);
		baseapi.httppost(utils.extendUrlVar(saveUrl, { id: formID.current, del: false, openNext: openNext }), submittedForm).then((response) => {
			response = response.data;

			if (!response.status) {
                utils.popupFormSetErrorForm(formRef, {
                    visible: true,
                    message: response.message,
                    type: "Danger"
                });
                return; 
			}
				// console.log(response);
				setDocNo(response.docNo);
				setOpenedBefore(response.openNext);
				setFormType(response.formType);
			// console.log(response.id);
			if (response.id !== "" && response.id !== undefined) {
				baseapi.httppost(utils.extendUrlVar(offsetSaveUrl, { id: response.id || "", del: false }), { Parent: formValue, Children: offsetDetails }).then((resp) => {
					const { data } = resp;
					if (!response.openNext) {
						clearForm();
						utils.popupFormSuccessfulSubmit(formRef, data);
						utils.popupFormClose(formRef);
					} else {
						// utils.popupFormClose(formRef);
						setFormType(formType === "Customer" ? "Supplier" : "Customer");
						// clearForm();
						utils.popupFormSuccessfulSubmit(formRef, data);

						if (data.status === true && response.openNext === true) {
							setOriFormType(props.formType === "Customer" ? "Supplier" : "Customer");
						}
					}

					utils.popupFormSetErrorForm(formRef, {
						visible: true,
						message: data.message,
						type: data.status ? "Success" : "Danger",
						action: data.action
					});

					// if (formID.current === "<NEW>" && docNo === null) {
					// 	console.log(tempForm.current);
					// 	if ((formType === "Customer" && tempForm.current.SupplierID === undefined) || (formType === "Supplier" && tempForm.current.CustomerID === undefined)) {
					// 		console.log(formType);
					// 		setFormType(formType === "Customer" ? "Supplier" : "Customer");
					// 	}
					// }
				});
			} else {
				const data = response;

				if (data.openNext) {
					clearForm();
					utils.popupFormSuccessfulSubmit(formRef, data);
					utils.popupFormClose(formRef);

					if (data.status === true && data.openNext) {
						setOriFormType(props.formType === "Customer" ? "Supplier" : "Customer");
					}
				} else {
					// setFormType(formType === "Customer" ? "Supplier" : "Customer");
					clearForm();
					utils.popupFormSuccessfulSubmit(formRef, data);
					// setOriFormType(props.formType);
				}

				utils.popupFormSetErrorForm(formRef, {
					visible: true,
					message: data.message,
					type: data.status ? "Success" : "Danger",
					action: data.action
				});
			}
		});
	};

	const valueOnChange = (e) => {
		const dataField = e.element.getAttribute("dataField");
		const copied = { ...tempForm.current };
		if (dataField === "Cancelled") {
			copied[dataField] = e.value;
			tempForm.current = copied;
			setFormValue(tempForm.current);
		}
	};

	return (
		<div>
			<PopupForm
				ref={formRef}
				onClosing={clearForm}
				fullScreen={false}
				width={"90%"}
				height={"90%"}
				headerClassName={formType === "Customer" ? "ar-module-form-title-bg popup-form-title" : "ap-module-form-title-bg popup-form-title"}
				onShown={onInitialized}
				onSuccessfulSubmit={({ stay }) => {
					if (stay) onInitialized();
				}}
				copyStorage={"ARContraCopiedData"}
				copyFields={{
					Parent: formValue
				}}
				onPasteForm={(e) => {
					tempForm.current = e.Parent;
					setFormValue(e.Parent);
					if (contraFormRef.current) {
						contraFormRef.current.setValue(e.Parent);
					}
				}}
				title={formType + " Contra"}
				ReportDocumentNo={formValue["DocumentNo"]}
				defaultSelectedReport={oriFormType === "Customer" ? "ZItyxy4VsRU%3d" : "kL40kPU3oGo%3d"}
				reportURL={oriFormType === "Customer" ? arContra.url : apContra.url}
				creditChecking={false}
				onValidating={setStartValidation}
				startValidation={startValidation}
				actionOnItemClick={formActionButton}
				isEditing={isEditing}
				onSaving={handleSubmit}
				formData={formValue}
				cancelDocumentCustomization={
					<span className='popup-form-header-cancel'>
						<CheckBox
							value={formValue["Cancelled"]}
							elementAttr={{ dataField: "Cancelled" }}
							onValueChanged={(e) => {
								valueOnChange(e);
							}}
							alignment='left'
							width={"12px"}
							height={"12px"}
							iconSize={"12px"}
							disabled={props.lockedForm === true}
							className='popup-header-cancel-input'
						/>

						<span className='popup-header-cancel-text'>Cancelled</span>
					</span>
				}>
				{formType === "Customer" && formID.current !== "<NEW>" && (
					<CustomerForm
						ref={contraFormRef}
						formID={formID.current}
						onValueChanged={(e) => {
							const parent = e.parent;
							tempForm.current = parent;
							setFormValue(tempForm.current);
						}}
					/>
				)}

				{formType === "Supplier" && formID.current !== "<NEW>" && (
					<SupplierForm
						ref={contraFormRef}
						formID={formID.current}
						onValueChanged={(e) => {
							const parent = e.parent;
							tempForm.current = parent;
							setFormValue(tempForm.current);
						}}
					/>
				)}

				{formID.current === "<NEW>" && formType === "Supplier" && (
					<SupplierForm
						ref={contraFormRef}
						formID={formID.current}
						onValueChanged={(e) => {
							const parent = e.parent;
							tempForm.current = parent;
							setFormValue(tempForm.current);
						}}
					/>
				)}

				{formID.current === "<NEW>" && formType === "Customer" && (
					<CustomerForm
						ref={contraFormRef}
						formID={formID.current}
						onValueChanged={(e) => {
							const parent = e.parent;
							tempForm.current = parent;
							setFormValue(tempForm.current);
						}}
					/>
				)}
			</PopupForm>
		</div>
	);
}
