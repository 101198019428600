import React, { useState, useRef, useEffect } from "react";
import DataGrid, { Button as Pager, Paging, Scrolling, Grouping, GroupPanel, SearchPanel, Toolbar, MasterDetail, Summary, TotalItem, Column, Selection } from "devextreme-react/data-grid";
import { Button } from "devextreme-react/button";
import { SelectBox } from "devextreme-react";
import { saveToLS, getFromLS } from "../../../utils/localstorage";
import { Popup } from "devextreme-react/popup";
import utils, { loading, closeLoading } from "../../../utils/common";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import baseapi from "../../../api/baseapi";
import ErrorPopUpForm from "../../popup-form/ErrorPopupForm";
import ListingReport from "./component-formats/listing";
import StatementReport from "./component-formats/statement";
import BalanceReport from "./component-formats/balance";
import AgeingReport from "./component-formats/ageing";
import ARSalesForm from "../../../pages/AR-Module/sales/form";
import ARReceiptForm from "../../../pages/AR-Module/receipt/form";
import ARCreditNoteForm from "../../../pages/AR-Module/credit-notes/form";
import ARDebitNoteForm from "../../../pages/AR-Module/debit-notes/form";
import APCreditNoteForm from "../../../pages/AP-Module/credit-notes/form";
import APDebitNoteForm from "../../../pages/AP-Module/debit-notes/form";
import APPurchaseForm from "../../../pages/AP-Module/purchase/form";
import APPaymentForm from "../../../pages/AP-Module/payment/form";
import ARRefundForm from "../../../pages/AR-Module/refund/form";
import APRefundForm from "../../../pages/AP-Module/refund/form";
import ContraForm from "../../../pages/contra/form";
import SalesInvoiceMainForm from "../../../pages/Sales-Module/invoice-main/form";
import SalesCashSalesForm from "../../../pages/Sales-Module/cash-sales/form";
import PurchaseInvoiceForm from "../../../pages/Purchase-Module/invoice/form";
import CashPurchaseForm from "../../../pages/Purchase-Module/cash-purchase/form";
import PathIndicator from "../../path-indicator/PathIndicator";
import WorkshopInvoiceForm from "../../../pages/Workshop-Module/Invoice/form";

export default function ReportComponent(props) {
	const isStatement = props.isStatement !== undefined ? props.isStatement : false;
	const isAR = props.isAR;
	const isBackOrder = props.isBackOrder;
	const isListing = props.isListing !== undefined ? props.isListing : false;
	const isBalance = props.isBalance !== undefined ? props.isBalance : false;
	const isAgeing = props.isAgeing !== undefined ? props.isAgeing : false;
	const isGL = props.isGL !== undefined ? props.isGL : false;
	const isWK = props.isWK !== undefined ? props.isWK : false;
	const moduleName = props.documentModule !== undefined ? props.documentModule : "";
	const className = props.className;
	const detailColumns = props.detailColumns;
	const summaryDetail = props.summaryDetail;
	const defaultReportName = props.reportName !== undefined ? props.reportName : "";
	const keyDetail = props.keyDetail;
	const listingTitle = props.listingTitle !== undefined ? props.listingTitle : null;
	const [isContra, setIsContra] = useState(false);

	const datagridRef = useRef(null);
	const [dropDownList, setDropDownList] = useState({
		reportList: []
	});
	const [documentModule, setDocumentModule] = useState("");
	const currentToken = localStorage.getItem("Authorization").substring(7);
	const [mainDataSource, setMainDataSource] = useState([]);
	const [detailDataSource, setDetailDataSource] = useState([]);
	// const [openSpecific, setOpenSpecific] = useState();
	const [openReportPop, setOpenReportPop] = useState(false);
	const [reportName, setReportName] = useState("");
	const [currentEditID, setCurrentEditID] = useState("");
	const [form, setForm] = useState("");
	const mainRef = useRef(null);
	const popupMessageRef = useRef(null);
	const [creditDebit, setCreditDebit] = useState(false); // false for Debit, true for Credit
	const [printRepDisabled, setPrintRepDisabled] = useState(true);
	const [enableMasterDetail, setEnableMasterDetail] = useState(true);

	// Handle Print Report click action, fetch report.
	const handlePrintBtn = (e) => {
		if (reportName) {
			baseapi.httpget(`${utils.ReportURL}/api/Report/getReportDetailsByReportName?reportID=${reportName}&accessToken=${currentToken}`).then((response) => {
				const { data } = response;
				data.map((items) => {
					confirmReportParameters(items.ID, items.ViewerAccessURL);
				});
			});
		}
	};

	// Construct report URL and open report.
	const confirmReportParameters = async (reportID, ViewerAccessURL) => {
		var documentViewerLink = ViewerAccessURL;

		try {
			var paramValue = mainRef.current.getParams();
			// console.log(paramValue);

			for (const param of paramValue) {
				switch (param.ParameterName) {
					case "FromDate":
					case "ToDate":
					case "StatementDate":
					case "AgeingAsAt":
					case "AgeingDateParam":
					case "FromCust":
					case "ToCust":
					case "FromSales":
					case "ToSales":
					case "FromSupp":
					case "ToSupp":
					case "FromArea":
					case "ToArea":
					case "FromDocNo":
					case "ToDocNo":
					case "FromChart":
					case "ToChart":
					case "FromMainProject":
					case "ToMainProject":
					case "FromDetailProject":
					case "ToDetailProject":
					case "FromMechanic":
					case "ToMechanic":
					case "FromServiceAdvisor":
					case "ToServiceAdvisor":
					case "FromStock":
					case "ToStock":
					case "FromStockGroup":
					case "ToStockGroup":
					case "FromProject":
					case "ToProject":
					case "FromRegister":
					case "ToRegister":
					case "FromJournal":
					case "ToJournal":
						documentViewerLink += `&${param.ParameterName}=${param.ParameterValue}`;
						break;
					case "CustList":
					case "SalesList":
					case "SuppList":
					case "AreaList":
					case "DocNoList":
					case "ChartList":
					case "MainProjectList":
					case "DetailProjectList":
					case "MechanicList":
					case "ServiceAdvisorList":
					case "JournalList":
					case "ProjectList":
					case "RegisterList":
					case "StockList":
					case "StockGroupList":
						documentViewerLink += `&${param.ParameterName}=${listingJoin(param.ParameterValue)}`;
						break;
					case "StatementType":
						switch (param.ParameterValue) {
							case "lw0I0Mvhves%3d":
								documentViewerLink += `&StatementType=0`;
								break;
							case "8uSJUCajIf8%3d":
								documentViewerLink += `&StatementType=1`;
								break;
							case "5oxwnlvCJSo%3d":
								documentViewerLink += `&StatementType=2`;
								break;
							default:
								documentViewerLink += `&StatementType=0`;
								break;
						}
						break;
				}
			}

			// console.log(documentViewerLink);

			window.open(documentViewerLink, "_blank");
		} catch (error) {
			console.error("Error fetching report parameters:", error);
		}
	};

	function listingJoin (listValue) {
		return listValue.replace("$%", ",");
	}

	// A map of document transaction types.
	const docType = [
		{
			TransType: "SA",
			DocumentType: "Sales"
		},
		{
			TransType: "RI",
			DocumentType: "Sales"
		},
		{
			TransType: "OR-GYYMM",
			DocumentType: "Receipt"
		},
		{
			TransType: "RR",
			DocumentType: "Receipt"
		},
		{
			TransType: "DB",
			DocumentType: "DebitNote"
		},
		{
			TransType: "SD",
			DocumentType: "DebitNote"
		},
		{
			TransType: "RD",
			DocumentType: "DebitNote"
		},
		{
			TransType: "RC",
			DocumentType: "CreditNote"
		},
		{
			TransType: "SC",
			DocumentType: "CreditNote"
		},
		{
			TransType: "CS-G",
			DocumentType: "CashSales"
		},
		{
			TransType: "PCP",
			DocumentType: "CashPurchase"
		},
		{
			TransType: "SCS",
			DocumentType: "CashSales"
		},
		{
			TransType: "INV-G",
			DocumentType: "Invoice"
		},
		{
			TransType: "INV",
			DocumentType: "Sales Invoice"
		},
		{
			TransType: "PPI",
			DocumentType: "Purchase Invoice"
		},
		{
			TransType: "INVWK",
			DocumentType: "WKInvoice"
		},
		{
			TransType: "OPP",
			DocumentType: "Purchase"
		},
		{
			TransType: "PP",
			DocumentType: "Purchase"
		},
		{
			TransType: "PC",
			DocumentType: "APCreditNote"
		},
		{
			TransType: "PVYYMM",
			DocumentType: "Payment"
		},
		{
			TransType: "PV",
			DocumentType: "Payment"
		},
		{
			TransType: "PD",
			DocumentType: "APDebitNote"
		},
		{
			TransType: "RRF",
			DocumentType: "Refund"
		},
		{
			TransType: "PRF",
			DocumentType: "APRefund"
		},
		{
			TransType: "ARR",
			DocumentType: "Refund"
		},
		{
			TransType: "APR",
			DocumentType: "APRefund"
		},
		{
			TransType: "RCT",
			DocumentType: "Contra"
		},
		{
			TransType: "CT",
			DocumentType: "Contra"
		},
		{
			TransType: "PCT",
			DocumentType: "APContra"
		},
		{
			TransType: "WI",
			DocumentType: "WKInvoice"
		},
	];

	useEffect(() => {
		if (props.onReportModuleChanged !== undefined) {
			props.onReportModuleChanged({ value: documentModule });
		}
	}, [documentModule]);

	//   const userID = JSON.parse(localStorage.getItem('data'));
	//   baseapi.httpget(domainName +"/api/User/GetUserDetailByID?id=" + userID?.userID).then(result2 => {
	//     const { data } = result2;
	//     // console.log(data);

	//     localStorage.setItem('Authorization', "Bearer " + data.Token);
	//     setCurrentToken(() => data.Token);
	//     if(currentToken !== data.Token) {
	//       navigate('/');
	//     }
	//   });
	// }, [currentToken]);

	// useEffect(() => {
	// 	console.log(isAR);
	// }, [isAR]);

	// Handles Inquiry button action, fetches data via getMain method
	// Collapses any currently expanded rows.
	const onInitialized = () => {
		loading("Loading Data");
		mainRef.current.getMain();
		if (datagridRef.current) {
			const dataGridInstance = datagridRef.current.instance;
			dataGridInstance.getVisibleRows().forEach((row) => {
				if (row.rowType === "data" && row.isExpanded) {
					dataGridInstance.collapseRow(row.key);
				}
			});
		}
	};

	function getDocTransCode(documentNo) {
		return baseapi.httpget("/api/Utilities/CheckDocumentNoExist", { documentNo: documentNo || "" }).then((response) => {
			const { data } = response;
			if (data.length > 0) {
				// console.log(data[0]);
				return data[0];
			} else {
				return null;
			}
		});
	}

	const getTasks = (key) =>
		new DataSource({
			store: new ArrayStore({
				data: detailDataSource,
				key: keyDetail
			}),
			filter: [keyDetail, "=", key]
		});

	// Template for detail section rows.
	function dataTemplate(props) {
		// console.log(props.data);
		const dataSource = getTasks(props.data.key);
		// console.log(dataSource.items());
		// console.log(detailColumns);
		// console.log(summaryDetail);

		if (!isContra) {
			return (
				<div>
					<DataGrid dataSource={dataSource} showBorders={true} columnAutoWidth={true} paging={false} >
						{/* <Paging defaultPageSize={10} /> */}
						{detailColumns.map((column) =>
							column.dataField === "DocNo" ? (
								<Column
									key={column.dataField}
									dataField={column.dataField}
									caption={column.caption}
									dataType={column.dataType}
									format={column.format}
									width={column.width}
									cellRender={(e) => {
										const data = e.data;
										const id = data.DocumentID;
										const nonIntegerRegex = /[a-zA-Z-]+/g;
										if (data.DocNo !== undefined) {
											if (isStatement) {
												return (
													<span
														className='clickable-span-tag'
														onClick={() => {
															loading();
															// console.log(data);
															getDocTransCode(data.DocNo).then((res) => {
																var valueToCheck = "";
																// console.log(res);

																if (res === null || res.transactionCode === "SC" || res.transactionCode === "SD") {
																	setCurrentEditID(id);
																	valueToCheck = data.DocumentPrefix.trim().match(nonIntegerRegex);
																} else {
																	setCurrentEditID(res.DocumentID);
																	valueToCheck = res.transactionCode.trim().match(nonIntegerRegex);
																}
																valueToCheck = valueToCheck ? valueToCheck.join("") : "";
																// console.log(data)

																docType.find((item) => {
																	if (item.TransType === valueToCheck) {
																		setForm(item.DocumentType);
																		if (isAR) {
																			if (data.Credit !== 0) {
																				setCreditDebit(true);
																			}
																			if (data.Debit !== 0) {
																				setCreditDebit(false);
																			}
																		} else {
																			if (data.Debit !== 0) {
																				setCreditDebit(true);
																			}
																			if (data.Credit !== 0) {
																				setCreditDebit(false);
																			}
																		}
																	}
																});
															});
															closeLoading();
														}}>
														{e.displayValue}
													</span>
												);
											}

											if (isBalance || isAgeing) {
												return (
													<span
														className='clickable-span-tag'
														onClick={() => {
															loading();
															// console.log(data);
															getDocTransCode(data.DocNo).then((res) => {
																var valueToCheck = "";
																// console.log(res);

																if (res === null) {
																	setCurrentEditID(id);
																	valueToCheck = data.TransactionTypeCode.trim().match(nonIntegerRegex);
																} else {
																	setCurrentEditID(res.DocumentID);
																	valueToCheck = res.transactionCode.trim().match(nonIntegerRegex);
																}
																valueToCheck = valueToCheck ? valueToCheck.join("") : "";

																docType.find((item) => {
																	if (item.TransType === valueToCheck) {
																		setForm(item.DocumentType);
																		if (isAR) {
																			if (data.Credit !== 0) {
																				setCreditDebit(true);
																			}
																			if (data.Debit !== 0) {
																				setCreditDebit(false);
																			}
																		} else {
																			if (data.Debit !== 0) {
																				setCreditDebit(true);
																			}
																			if (data.Credit !== 0) {
																				setCreditDebit(false);
																			}
																		}
																	}
																});
															});
															closeLoading();
														}}>
														{e.displayValue}
													</span>
												);
											} else {
												return e.displayValue;
											}
										} else {
											return e.displayValue;
										}
									}}
								/>
							) : (
								<Column key={column.dataField} dataField={column.dataField} caption={column.caption} dataType={column.dataType} visible={column.visible} format={column.format} width={column.width} />
							)
						)}

						<Summary>
							{summaryDetail.map((item) => (
								<TotalItem key={item.key} column={item.column} summaryType={item.summaryType} customizeText={item.customizeText} showInColumn={item.showInColumn} />
							))}
						</Summary>
					</DataGrid>
				</div>
			);
		}
	}
	
	const storageName = props.storageName !== undefined ? props.storageName : null;
	
	const onRowPrepared = (e) => {
		if (e.rowType === "data") {
			const gridInstance = e.component;
			//Highlight the row if it is cancelled
			const totalRows = gridInstance.getDataSource().totalCount();
			const userLockCount = props.userLockCount || 0; // Default to 0 if not passed

			// Check if the current row is one of the last `userLockCount` rows
			if (totalRows > 0 && totalRows - userLockCount <= e.rowIndex) {
				e.rowElement.classList.add('locked-user-row');
			}
			
			if(!utils.isNullOrEmpty(e.data["canExpand"])) {
				if(!e.data["canExpand"]) {
					e.cells[0].cellElement.classList.remove('dx-datagrid-expand');  
					  e.cells[0].cellElement.childNodes[0].classList.remove('dx-datagrid-group-closed');  
				}
			}
		}

		if (e.rowType === "header" && storageName !== null) {
			const find = e.columns.find((c) => c.command === "transparent");
			//Ignore the fixed columns
			if (utils.isNullOrEmpty(find)) {
				const columns = e.columns;
				const previousRecord = getFromLS(storageName);

				if (previousRecord === undefined || previousRecord.length !== columns.length) {
					for (var i = 0; i < columns.length; i++) {
						columns[i]["columnWidth"] = null;
					}
				} else {
					for (var i = 0; i < columns.length; i++) {
						columns[i]["columnWidth"] = previousRecord[i]["columnWidth"];
					}
				}

				saveToLS(storageName, columns);
			}
		}
	};

	const onOptionChanged = (e) => {
		if (e.name === "columns" && e.fullName.includes("width") && storageName !== null) {
			//Sample format : columns[0].width
			const columns = getFromLS(storageName);
			const columnIndex = parseInt(e.fullName.substring(8, 9));
			const arrayIndex = columns.findIndex((x) => x.index === columnIndex);

			// Save the new width
			columns[arrayIndex]["columnWidth"] = e.value;
			saveToLS(storageName, columns);
		}
	};

	useEffect(() => {
		// console.log(props.reportList);
		if (props.reportList !== undefined) {
			setDropDownList({
				reportList: props.reportList
			});
		}
	}, [props.reportList]);

	return (
		<div>
			<Popup visible={openReportPop} width={"auto"} height={"auto"} dragEnabled={false} title='Print Report' showCloseButton={false}>
				<div className='popup-group-form-item'>
					<div className='popup-group-form-label'>Report: </div>
					<SelectBox
						value={reportName}
						elementAttr={{ dataField: "ReportName" }}
						onValueChanged={(e) => {
							setReportName(e.value);
							// valueOnChange(e);
						}}
						alignment='left'
						width={"450px"}
						dataSource={dropDownList.reportList}
						displayExpr={"code"}
						valueExpr={"id"}
						searchEnabled={true}
						showClearButton={true}
					/>
				</div>
				<div className='popup-group-form-item' style={{ marginTop: "15px", justifyContent: "flex-end" }}>
					<Button text='Cancel' type='normal' width={"auto"} style={{ marginLeft: "20px" }} onClick={(e) => setOpenReportPop(false)} />
					<Button text='Print Report' type='success' width={"auto"} style={{ marginLeft: "20px" }} onClick={(e) => handlePrintBtn(e)} />
				</div>
			</Popup>

			<div>
				<div className={"content-block"}>
					<div>
						<div className='listing-page-title-container'>
							<span className='datagrid-customized-title'>
								<PathIndicator />
							</span>
						</div>
						<div style={{ marginTop: "10px" }}>
							<div style={{ backgroundColor: "#EFEFEF", borderRadius: "15px", width: "85%", padding: "0.7%" }}>
								{isStatement && (
									<StatementReport
										ref={mainRef}
										isAR={isAR}
										reportName={defaultReportName}
										className={className}
										onValueChanged={(e) => {
											const parent = e.parent;
											mainRef.current = parent;
											setMainDataSource(mainRef.current);
											setDetailDataSource(e.children);
										}}
										onButtonDisable={(e) => {
											const disable = e.value;
											mainRef.current = disable;
											setPrintRepDisabled(disable);
										}}
									/>
								)}

								{isListing && (
									<ListingReport
										ref={mainRef}
										moduleName={moduleName}
										isGL={isGL}
										isWK={isWK}
										isBackOrder={isBackOrder}
										reportName={defaultReportName}
										className={className}
										isContra={(e) => {
											setIsContra(e.value);
										}}
										onValueChanged={(e) => {
											const parent = e.parent;
											mainRef.current = parent;
											setMainDataSource(mainRef.current);
											setDetailDataSource(e.children);
										}}
										onReportModuleChanged={(e) => {
											// console.log(e);
											if (e.value !== undefined) {
												setDropDownList((prevState) => ({
													...prevState,
													reportList: e.value
												}));
											}

											if (e.documentModule !== undefined) {
												setDocumentModule(e.documentModule);
											}
										}}
										onButtonDisable={(e) => {
											const disable = e.value;
											mainRef.current = disable;
											setPrintRepDisabled(disable);
										}}
									/>
								)}

								{isBalance && (
									<BalanceReport
										ref={mainRef}
										isAR={isAR}
										reportName={defaultReportName}
										className={className}
										hasDetailItems={(e) => {
											setEnableMasterDetail(e.value);
										}}
										onValueChanged={(e) => {
											const parent = e.parent;
											mainRef.current = parent;
											setMainDataSource(mainRef.current);
											setDetailDataSource(e.children);
										}}
										onButtonDisable={(e) => {
											const disable = e.value;
											mainRef.current = disable;
											setPrintRepDisabled(disable);
										}}
									/>
								)}

								{isAgeing && (
									<AgeingReport
										ref={mainRef}
										isAR={isAR}
										reportName={defaultReportName}
										className={className}
										onValueChanged={(e) => {
											const parent = e.parent;
											mainRef.current = parent;
											setMainDataSource(mainRef.current);
											setDetailDataSource(e.children);
										}}
										onButtonDisable={(e) => {
											const disable = e.value;
											mainRef.current = disable;
											setPrintRepDisabled(disable);
										}}
									/>
								)}
							</div>

							<div className='popup-group-form-item' style={{ marginTop: "10px" }}>
								<Button text='Inquiry' type='success' width={"auto"} onClick={(e) => onInitialized(e)} />
								<Button text='Print Report' disabled={printRepDisabled} type='success' stylingMode={"outlined"} width={"auto"} style={{ marginLeft: "20px" }} onClick={(e) => setOpenReportPop(true)} />
							</div>

							<DataGrid ref={datagridRef} keyExpr={keyDetail} dataSource={mainDataSource} showBorders={true} paging={false} columnAutoWidth={true} allowColumnResizing={true} allowColumnReordering={true}
								onRowPrepared={(e) => onRowPrepared(e)}
								onOptionChanged={onOptionChanged}
							>
								<MasterDetail enabled={props.disableDetails /*&& enableMasterDetail*/} component={dataTemplate} chi/>
								{/* <Paging defaultPageSize={15} /> */}
								{/* {props.children} */}
								{!isListing && Array.isArray(props.children) && props.children.map((child) => {
									if (!utils.isNullOrEmpty(child) && !utils.isNullOrEmpty(child.props)) {
										if (child.props.type !== "buttons") {
											const column = child.props;
											const columnWidth = !utils.isNullOrEmpty(column.width) ? column.width : utils.getColumnWidth(column.dataField, storageName) === null ? undefined : utils.getColumnWidth(column.dataField, storageName);
											const columnIndex = utils.getColumnVisibleIndex(column.dataField, storageName) === null ? 0 : utils.getColumnVisibleIndex(column.dataField, storageName);
											const columnEditorOptions = utils.isNullOrEmpty(column.editorOptions) ? {} : column.editorOptions;

											const gridProps = {
												width: columnWidth,
												visibleIndex: columnIndex,
												caption: utils.isNullOrEmpty(column.caption) ? utils.childGridDefaultSetting(column.dataField, "Caption") : column.caption,
												editorOptions: columnEditorOptions,
												format: utils.isNullOrEmpty(column.format) ? utils.childGridDefaultSetting(column.dataField, "Format") : column.format,
												customizeText: utils.isNullOrEmpty(column.customizeText) ? utils.childGridDefaultSetting(column.dataField, "Customize Text") : column.customizeText
											};
											return React.cloneElement(child, gridProps);
										}
									}
								})}

								{isListing && props.children}
							</DataGrid>
						</div>
					</div>
				</div>
			</div>

			{isStatement && isAR && (
				<div>
					{creditDebit ? (
						<div>
							{form === "Sales" && <ARSalesForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={true} title={"A/R Invoice"} />}
							{/* {(form === "CashSales" || form === "Receipt" || form === "WKInvoice") && <ARReceiptForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Receipt Entry"} />} */}
							{form === "Receipt" && <ARReceiptForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Receipt Entry"} />}
							{form === "CreditNote" && <ARCreditNoteForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Credit Note Entry"} />}
							{form === "Contra" && <ContraForm formID={currentEditID} closePopup={setCurrentEditID} formType={"Customer"} lockedForm={false} />}
							{form === "CashSales" && <SalesCashSalesForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={true} title={"Cash Sales Entry"} />}
							{form === "WKInvoice" && <WorkshopInvoiceForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Invoice Entry"} />}
						</div>
					) : (
						<div>
							{form === "Sales" && <ARSalesForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={true} title={"A/R Invoice"} />}
							{form === "DebitNote" && <ARDebitNoteForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={true} title={"Debit Note Entry"} />}
							{/* {form === "Receipt" && <ARReceiptForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={true} />} */}
							{form === "Invoice" && <ARSalesForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={true} title={"Invoice Entry"} />}
							{form === "CashSales" && <SalesCashSalesForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={true} title={"Cash Sales Entry"} />}
							{form === "Sales Invoice" && <SalesInvoiceMainForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Invoice Entry"} />}
							{form === "Refund" && <ARRefundForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Refund Entry"} />}
							{form === "Contra" && <ContraForm formID={currentEditID} closePopup={setCurrentEditID} formType={"Customer"} lockedForm={false} />}
							{form === "CreditNote" && <ARCreditNoteForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Credit Note Entry"} />}
							{form === "WKInvoice" && <WorkshopInvoiceForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Invoice Entry"} />}
							{/* {form === "WKInvoice" && <WorkshopInvoiceForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={true} />} */}
						</div>
					)}
				</div>
			)}

			{isStatement && !isAR && (
				<div>
					{creditDebit ? (
						<div>
							{form === "Purchase" && <APPurchaseForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Purchase Entry"} />}
							{form === "APCreditNote" && <APCreditNoteForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Credit Note Entry"} />}
							{form === "Payment" && <APPaymentForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Payment Entry"} />}
							{form === "APContra" && <ContraForm formID={currentEditID} closePopup={setCurrentEditID} formType={"Supplier"} lockedForm={false} />}
							{form === "CashPurchase" && <CashPurchaseForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={true} title={"Cash Purchase Entry"} />}
						</div>
					) : (
						<div>
							{form === "Purchase" && <APPurchaseForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Purchase Entry"} />}
							{form === "APDebitNote" && <APDebitNoteForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Debit Note Entry"} />}
							{form === "Payment" && <APPaymentForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Payment Entry"} />}
							{form === "APRefund" && <APRefundForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Refund Entry"} />}
							{form === "APContra" && <ContraForm formID={currentEditID} closePopup={setCurrentEditID} formType={"Supplier"} lockedForm={false} />}
							{form === "APCreditNote" && <APCreditNoteForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Credit Note Entry"} />}
							{form === "CashPurchase" && <CashPurchaseForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={true} title={"Cash Purchase Entry"} />}
							{form === "Purchase Invoice" && <PurchaseInvoiceForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Invoice Entry"} />}
						</div>
					)}
				</div>
			)}

			{(isBalance || isAgeing) && isAR && (
				<div>
					{(form === "Sales" || form === "Invoice") && <ARSalesForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Invoice Entry"} />}
					{form === "DebitNote" && <ARDebitNoteForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Debit Note Entry"} />}
					{form === "Receipt" && <ARReceiptForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Receipt Entry"} />}
					{form === "CreditNote" && <ARCreditNoteForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Credit Note Entry"} />}
					{form === "Refund" && <ARRefundForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Refund Entry"} />}
					{form === "Contra" && <ContraForm formID={currentEditID} closePopup={setCurrentEditID} formType={"Customer"} lockedForm={false} />}
					{form === "CashSales" && <SalesCashSalesForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={true} title={"Cash Sales Entry"} />}
					{form === "Sales Invoice" && <SalesInvoiceMainForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Invoice Entry"} />}
					{form === "WKInvoice" && <WorkshopInvoiceForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Invoice Entry"} />}
				</div>
			)}

			{(isBalance || isAgeing) && !isAR && (
				<div>
					{form === "Purchase" && <APPurchaseForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Purchase Entry"} />}
					{form === "APDebitNote" && <APDebitNoteForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Debit Note Entry"} />}
					{form === "Payment" && <APPaymentForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Payment Entry"} />}
					{form === "APCreditNote" && <APCreditNoteForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Credit Note Entry"} />}
					{form === "APRefund" && <APRefundForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Refund Entry"} />}
					{form === "APContra" && <ContraForm formID={currentEditID} closePopup={setCurrentEditID} formType={"Supplier"} lockedForm={false} />}
					{form === "CashPurchase" && <CashPurchaseForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={true} title={"Cash Purchase Entry"} />}
					{form === "Purchase Invoice" && <PurchaseInvoiceForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Invoice Entry"} />}
				</div>
			)}

			<ErrorPopUpForm ref={popupMessageRef} />
		</div>
	);
}
