import React, { useEffect, useState, useRef } from "react";
import PopupForm from "../../components/popup-form/PopupFormV2";
import baseapi from "../../api/baseapi";
import { CheckBox } from "devextreme-react/check-box";
import { TextBox } from "devextreme-react/text-box";
import utils from "../../utils/common";
import { loading, closeLoading } from "../../utils/common";
import DataGrid, { LoadPanel, Column, Editing, Selection, Scrolling, Lookup, KeyboardNavigation, ColumnChooser, Summary, TotalItem, Sorting, Pager, Paging, Item as ToolbarItem } from "devextreme-react/data-grid";
import { DateBox, NumberBox } from "devextreme-react";
import Swal from "sweetalert2";

export default function EInvoiceMain(props) {
    const [invoiceDetail, setInvoiceDetail] = useState(null);


    const [invoiceDetailDataSource, setInvoiceDetailDataSource] = useState([]);


    const formRef = useRef(null);


    useEffect(() => {

        if (props.formID !== null) {
            utils.popupFormOpen(formRef);
        }
    }, [props.formID]);

 

    async function onInitialized(e) {
        loading();
        let result = await baseapi.httpget('/api/einvoice/SubmitDocument?docNo=' + props.formID + '&invCode=' + props.invoiceType);
        setInvoiceDetail(result?.data?.data);
        setInvoiceDetailDataSource(result?.data?.data?.InvoiceDetail);

        closeLoading();
        if (result?.data?.status == 'invalid') {
            Swal.fire({
                type: "info",
                title: "Opps!",
                text: result?.data?.error?.message,
                showConfirmButton: false
            });
        }


    }

    const clearForm = () => {
        setInvoiceDetail(null);

        props.closePopup(null);
    };




    return (
        <div>
            <PopupForm
                ref={formRef}
                onClosing={clearForm}
                fullScreen={false}
                actionButtonEnabled={false}
                disableButtons={true}
                width={"95%"}
                height={"90%"}
                headerClassName={"e-invoice-module-form-title-bg popup-form-title"}
                title={props.title}
                onShown={onInitialized}
            >


                <div className='popup-group-form-group' id='bottom-form-group'>
                    <h4 style={{ marginBlock: '10px', marginLeft: '20px' }}>E-Invoice Info</h4>
                </div>
                <div className='popup-form-main-content'>
                    <div className='e-invoice-module-parent-section custom-form-grid-section1'>
                        <div className=''>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>UUID: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.UUID}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >

                                    </TextBox>
                                </div>
                            </div>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>Submission UID: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.submissionUID}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>Long ID: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.LongID}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>Digital Signature: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox

                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>Version: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.typeVersionName}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>Link: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.url}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>Reception Date: </div>

                                <div className='popup-group-form-input'>
                                    <DateBox
                                        type="datetime"
                                        value={invoiceDetail?.dateTimeIssued}
                                        elementAttr={{ dataField: "CustomerName" }}
                                        dateSerializationFormat='yyyy-MM-dd HH:mm:ss'
                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </DateBox>
                                </div>
                            </div>

                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>Validate Date: </div>

                                <div className='popup-group-form-input'>
                                    <DateBox
                                        type="datetime"
                                        value={invoiceDetail?.dateTimeValidated}
                                        elementAttr={{ dataField: "CustomerName" }}
                                        dateSerializationFormat='yyyy-MM-dd HH:mm:ss'
                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </DateBox>
                                </div>
                            </div>

                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>Cancel Date: </div>

                                <div className='popup-group-form-input'>
                                    <DateBox

                                        type="datetime"
                                        value={invoiceDetail?.cancelDateTime}
                                        elementAttr={{ dataField: "CustomerName" }}
                                        dateSerializationFormat='yyyy-MM-dd HH:mm:ss'
                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </DateBox>
                                </div>
                            </div>

                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>Cancel Reason: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.documentStatusReason}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>Status: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.status}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>

                        </div>



                        <div className='popup-form-item-container'>
                            <div className='popup-group-form-item'>
                                <img src={invoiceDetail?.qrcode} style={{ width: '250px', height: '250px' }} />
                            </div>

                        </div>


                    </div>

                </div>

                <div className='popup-group-form-group' id='bottom-form-group'>
                    <h4 style={{ marginBlock: '10px', marginLeft: '20px' }}>Document Info</h4>
                </div>

                <div className='popup-form-main-content'>
                    <div className='e-invoice-module-parent-section custom-form-grid-section1'>
                        <div className=''>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>Document Type: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.DocumentType}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>Internal ID: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.DocumentNo}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>Forex Code: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.ForexCode}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>

                        </div>

                        <div className='popup-form-item-container2'>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>Local Rate: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.LocalRate}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>Transaction Date: </div>

                                <div className='popup-group-form-input'>
                                    <DateBox
                                        type="datetime"
                                        value={invoiceDetail?.dateTimeIssued}
                                        elementAttr={{ dataField: "CustomerName" }}
                                        dateSerializationFormat='yyyy-MM-dd HH:mm:ss'
                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </DateBox>

                                </div>
                            </div>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>Forex Rate: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.ForexRate}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-input'>

                                    <CheckBox

                                        text='Foreign Buyer'
                                    />
                                    <CheckBox style={{ marginTop: 10 }}
                                        text='Government Buyer'
                                    />


                                </div>
                                <div className='popup-group-form-input'>
                                    <CheckBox
                                        value={invoiceDetail?.IsConsolidated}
                                        text='Consolidated'
                                    />




                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className='popup-group-form-group' id='bottom-form-group'>
                    <h4 style={{ marginBlock: '10px', marginLeft: '20px' }}>Buyer Info</h4>
                </div>

                <div className='popup-form-main-content'>
                    <div className='e-invoice-module-parent-section custom-form-grid-section1'>
                        <div className=''>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>Name: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.CustomerName}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>Address 1: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.CustomerAddress1}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>Address 2: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.CustomerAddress2}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>Address 3: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.CustomerAddress3}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>Post Code: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.PostCode}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>
                        </div>

                        <div className='popup-form-item-container2'>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>Register No: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.BuyerRegistrationNumber}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>Identity Type: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>

                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>Tax Identification (TIN): </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.BuyerTIN}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>

                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>SST: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.SSTSalesNo}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>


                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>Handphone: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.HandPhone}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>Email: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.Email}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>



                        </div>
                    </div>

                </div>


                <div className='popup-form-main-content'>
                    <div className='e-invoice-module-parent-section custom-form-grid-section1'>
                        <div className=''>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>City: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                                <div className='popup-group-form-input'>
                                    <TextBox
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>State: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.State}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.StateCode}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>Country: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.Country}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.CountryCode}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='popup-group-form-group' id='bottom-form-group'>
                    <h4 style={{ marginBlock: '10px', marginLeft: '20px' }}>Seller Info</h4>
                </div>

                <div className='popup-form-main-content'>
                    <div className='e-invoice-module-parent-section custom-form-grid-section1'>
                        <div className=''>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>S Name: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.SupplierName}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>S Address 1: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.SupplierAddress1}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>S Address 2: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.SupplierAddress2}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>S Address 3: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.SupplierAddress3}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>S Post Code: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.SupplierPostCode}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>Business Activity: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.BusinessActivity}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>
                        </div>

                        <div className='popup-form-item-container2'>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>S Register No: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.SupplierRegistrationNumber}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>MSIC: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        elementAttr={{ dataField: "CustomerName" }}
                                        value={invoiceDetail?.MSIC}
                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>

                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>S Tax Identification (TIN): </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.SupplierTIN}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>

                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>S SST: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.SupplierSSTSalesNo}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>


                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>S Handphone: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.SupplierHandPhone}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>S Email: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.SupplierEmail}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>



                        </div>
                    </div>

                </div>




                <div className='popup-form-main-content'>
                    <div className='e-invoice-module-parent-section custom-form-grid-section1'>
                        <div className=''>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>City: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                                <div className='popup-group-form-input'>
                                    <TextBox
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>S State: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.SupplierState}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.SupplierStateCode}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>S Country: </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.SupplierCountry}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={invoiceDetail?.SupplierCountryCode}
                                        elementAttr={{ dataField: "CustomerName" }}

                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </TextBox>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


                <div className='popup-form-main-content'>
                    <div className='e-invoice-module-parent-section custom-form-grid-section1'>
                        <div className=''>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>Line Ext Amt: </div>

                                <div className='popup-group-form-input'>
                                    <NumberBox
                                        value={invoiceDetail?.LineExtAmt}
                                        elementAttr={{ dataField: "CustomerName" }}
     format="#,##0.00"
                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </NumberBox>
                                </div>
                            </div>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>Tax Ext Amt: </div>

                                <div className='popup-group-form-input'>
                                    <NumberBox
 format="#,##0.00"
                                        elementAttr={{ dataField: "CustomerName" }}
                                        value={invoiceDetail?.TaxExtAmt}
                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </NumberBox>
                                </div>
                            </div>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>Tax Inc Amt: </div>

                                <div className='popup-group-form-input'>
                                    <NumberBox
 format="#,##0.00"
                                        elementAttr={{ dataField: "CustomerName" }}
                                        value={invoiceDetail?.RoundingAmount}
                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </NumberBox>
                                </div>
                            </div>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>Discount Amt: </div>

                                <div className='popup-group-form-input'>
                                    <NumberBox
 format="#,##0.00"
                                        elementAttr={{ dataField: "CustomerName" }}
                                        value={invoiceDetail?.ItemDiscountAmt}
                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </NumberBox>
                                </div>
                            </div>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>Payable Amt: </div>

                                <div className='popup-group-form-input'>
                                    <NumberBox
                                        value={invoiceDetail?.RoundingAmount}
                                        elementAttr={{ dataField: "CustomerName" }}
 format="#,##0.00"
                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </NumberBox>
                                </div>
                            </div>
                        </div>

                        <div className='popup-form-item-container'>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>Tax Total Amt: </div>

                                <div className='popup-group-form-input'>
                                    <NumberBox
                                        value={invoiceDetail?.RoundingAmount}
                                        elementAttr={{ dataField: "CustomerName" }}
                                        alignment='left'
                                        width={"auto"}
                                         format="#,##0.00"
                                    >
                                    </NumberBox>
                                </div>
                            </div>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>Taxable Amt: </div>

                                <div className='popup-group-form-input'>
                                    <NumberBox
                                        elementAttr={{ dataField: "CustomerName" }}
                                        value={invoiceDetail?.LineExtAmt}
                                        alignment='left'
                                        width={"auto"}
                                         format="#,##0.00"
                                    >
                                    </NumberBox>
                                </div>
                            </div>

                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>Tax Amt: </div>

                                <div className='popup-group-form-input'>
                                    <NumberBox
                                        value={invoiceDetail?.TaxExtAmt}
                                        elementAttr={{ dataField: "CustomerName" }}
                                        alignment='left'
                                        width={"auto"}
                                         format="#,##0.00"
                                    >
                                    </NumberBox>
                                </div>
                            </div>
                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>Rounding: </div>

                                <div className='popup-group-form-input'>
                                    <NumberBox
                                        value={invoiceDetail?.RoundingAmount}
                                        elementAttr={{ dataField: "CustomerName" }}
 format="#,##0.00"
                                        alignment='left'
                                        width={"auto"}
                                    >
                                    </NumberBox>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>



                <div className='popup-form-main-content' style={{}}>

                    <div className='inventory-module-border-bottom e-invoice-module-parent-section custom-form-grid-section' style={{}}>

                        <DataGrid
                            id="id"
                            showBorders={true}
                            width={"100%"}
                            dataSource={invoiceDetailDataSource}
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            rowHeight={50}
                            hoverStateEnabled={true}
                            rowAlternationEnabled={false}
                            columnAutoWidth={true}
                            errorRowEnabled={false}
                            loadPanel={{ enabled: true }}
                            height={400}
                            focusedRowEnabled={false}
                        >

                            <Sorting mode="multiple" />
                            <Column dataField='No' width="120px" caption="No" editorOptions={{ disabled: true }} />
                            <Column dataField='StockCode' caption={"Stock Code"} editorOptions={{ readOnly: true }} width="250px" />
                            <Column dataField='Description' caption={"Description"} editorOptions={{ readOnly: true }} width="400px" />
                            <Column dataField='Loc' caption="Loc" editorOptions={{ disabled: true }} width="80px" />
                            <Column dataField='Qty' caption="Qty" dataType="number" editorOptions={{ disabled: true }} width="100px" />
                            <Column dataField='UOMCode' caption="UOM" dataType="number" editorOptions={{ disabled: true }} width="80px" />
                            <Column dataField='U_Price' format='#,##0.00' caption="U/Price" dataType="number" editorOptions={{ disabled: true }} width="120px" />
                            <Column dataField='Disc' caption="Disc%" width="90px" />
                            <Column dataField='Amount' format='#,##0.00' caption="Amount" width="90px" />
                            <Column dataField='DescAmt'  format='#,##0.00' caption="Desc Amt" editorOptions={{ disabled: true }} width="80px" />
                            <Column dataField='InternalTaxCode' caption="Tax Code" editorOptions={{ disabled: true }} />

                            <Scrolling columnRenderingMode='standard' showScrollbar={"onHover"} />

                            <Pager allowedPageSizes={[10, 25, 50, 100]} showPageSizeSelector={true} visible={true} displayMode={"full"} showInfo={true} />
                            <Paging
                                defaultPageSize={10}
                                onPageSizeChange={(e) => {

                                }}
                                onPageIndexChange={(e) => {


                                }}
                            />
                        </DataGrid>



                    </div>
                </div>

            </PopupForm>


        </div>
    );
}
