
import { useEffect, useRef, useState, forwardRef, useCallback, useMemo } from "react";

import Container from '../../../components/container';
import utils from '../../../utils/common';
import PathIndicator from "../../../components/path-indicator/PathIndicator";
import baseapi from "../../../api/baseapi";
import { loading, closeLoading, fetchDefaultValues } from "../../../utils/common";
import { stockOptions, stockGroupOptions, itemTypeOptions, brandOptions, categoryOptions, workshopModel, locationOptions } from "../../../utils/lookupstore";
import { DataGrid, Button as Pager, Toolbar, Column, Selection, MasterDetail, Paging, Summary, TotalItem, ValueFormat, } from "devextreme-react/data-grid";
import StockReportListing from "./StockReportListing";


export default function StockBalanceListing() {


    const [mainDataSource, setMainDataSource] = useState([]);
    const [detailDataSource, setDetailDataSource] = useState([]);
    const [lockForm, setLockForm] = useState(false);
    const [currentEditID, setCurrentEditID] = useState(null);
    const [currentPrefix, setCurrentEditPrefix] = useState(null);
    const formID = useRef(null);
    const [listingTransactionSource, setListingTransactionSource] = useState([]);
    const [originalListingTransactionSource, setOriginalListingTransactionSource] = useState([]);
    const stockReportListingRef = useRef(null);
    const [defaultValue, setDefaultValue] = useState({});
    function handleEdit(e) {
        const id = e.row.key;
        setLockForm(utils.isLockedForm(e));
        setCurrentEditID(id);
    }
    const handleFormSubmitSuccess = () => {
        loading("Loading Latest Stock Details...");

        baseapi.httpget("/api/StockDetailAnalysis/GetAnalysisTransaction?id=" + formID.current)
            .then((response) => {
                const { data } = response;

                setListingTransactionSource(data.transactions);
                setOriginalListingTransactionSource(data.transactions);

            })
            .catch((error) => {
                console.error('Error fetching stock details:', error);
            })
            .finally(() => {

                setTimeout(() => {
                    closeLoading();
                }, 300);

            });
    };

    const [currentForm, setCurrentForm] = useState(null);
    const handleRowClick = (data) => {
        setCurrentEditID(null);
        setCurrentEditPrefix(null);
        loading();
        setCurrentForm(null);
        setTimeout(() => {

            closeLoading();
        }, 300);
        setTimeout(() => {
            setCurrentEditID(data.DocumentID);
            setCurrentEditPrefix(data.DocumentPrefix);
        }, 0);
        // Using a setTimeout with a short delay to ensure state is updated correctly

    };
    useEffect(() => {
        const getDefaultValues = async () => {
            const data = await fetchDefaultValues();
            if (data) {
                setDefaultValue(data);
            }
        };

        getDefaultValues();
    }, []);
    return <Container>

        <div>
            <div className='listing-page-title-container'>
                <span className='datagrid-customized-title'>
                    <PathIndicator />
                </span>
            </div>

            <StockReportListing
                ref={stockReportListingRef}
                reportName={"Stock Balance"}
                reportID={"Stock Balance"}
                defaultValue={defaultValue}
                ReturnResult={(e) => {
                    setMainDataSource(e.MainData);
                    setDetailDataSource(e.Details.flat());
                }}
            />
            <div style={{ marginTop: '20px' }}>
                <div className='popup-group-form-item'>

                    <DataGrid width={"100%"} dataSource={mainDataSource} showBorders={true} columnAutoWidth={true} paging={{ enabled: false }} sorting={{ mode: "multiple" }} // Enable sorting
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                    >
                        <Column dataField='StockCode' editorOptions={{ disabled: true }}  width="150px"/>
                        <Column dataField='StockDescription' caption="Description" editorOptions={{ disabled: true }} width="280px" />
                        <Column dataField='StockGroupCode' caption="Stock Group"editorOptions={{ disabled: true }} />
                        <Column dataField='ItemTypeCode' caption="Item Type"editorOptions={{ disabled: true }} />
                        <Column dataField='ShelfCode' caption="Shelf" editorOptions={{ disabled: true }} />
                        <Column dataField='LocationCode' caption="Location" editorOptions={{ disabled: true }}  width="100px"/>
                        <Column dataField='NetQuantityFormat' caption="Net Qty" editorOptions={{ disabled: true }} width="100px" />
                        <Column dataField='TotalCost' editorOptions={{ disabled: true }}   format={{ type: 'fixedPoint', precision: 2 }}/>
                        <Column dataField='AverageCost' editorOptions={{ disabled: true }}  format={{ type: 'fixedPoint', precision: 2 }}/>
                        <Paging defaultPageSize={15} />
                    </DataGrid>

                </div>

            </div>
           
        </div>

    </Container>



}


