import React, { useEffect, useState, useRef } from "react";
import DataGrid, { Column, Lookup, Button as ButtonColumn, Sorting, Scrolling, Pager,Paging } from "devextreme-react/data-grid";
import Container from "../../../components/container";
import { forexOptions } from "../../../utils/lookupstore";
import Listing from "../../../components/listing/newListing";
import utils from "../../../utils/common";
import DropDownButton from "devextreme-react/drop-down-button";
import EInvoiceMain from "../../../components/e-invoice/EInvoiceMain";

export default function ConsolidatedEInvoicesListing() {
	const [currentEditID, setCurrentEditID] = useState(null);
	const [currentDetailID, setCurrentDetailID] = useState(null);
	const [forexList, setForexList] = useState([]);
	const [dropDownRead, setDropDownRead] = useState(false);
	const [transformTitle, setTransformTitle] = useState(null);
	const [transferData, setTransferData] = useState(null);
	const transformRef = useRef(null);

	const [invType, setInvType] = useState(null);

	const apiURL = `/api/SalesCashSales`;
	const listingAPIURL = `/api/SalesCashSales/list`;

	useEffect(() => {
	

		if (dropDownRead !== true) {
			forexOptions.store.load().then((list) => {
				setForexList(list.data);
			});
			setDropDownRead(true);
		}

	
	}, [dropDownRead]);







	const closePopup = (value) => {
		setCurrentEditID(value);
		setCurrentDetailID(value);
		setTransferData(null);
	};



	return (
		<Container>
			<div id='normal-listing'>
				<Listing
					className='sales-listing-datagrid'
					apiURL={apiURL}
					listingURL={listingAPIURL + '?isCons=true&page=1&rows=100'}
					sortColumn={"DocumentNo"}
					listingTitle={"Cash Sales"}
					storageName={"SalesCashSalesListingColumnWidth"}
					addShow={true}
					submitShow={true}
					submitButtonOnClick={()=>{
		setInvType('Consolidated');
						setCurrentDetailID('CINV-' + new Date().getFullYear() + (new Date().getMonth() + 1) + new Date().getDate() + new Date().getSeconds())
					}}
				>
					<Column dataField='DocumentNo' />

					<Column dataField='DocumentDate' format='dd/MM/yyyy' dataType={"date"} />

					<Column dataField='CustomerCode' />

					<Column dataField='CustomerName' />

					<Column dataField='ForexCode' caption={"Forex"} />

					<Column dataField='MainForexAmount' caption={"Cash Sales Amount"} format={{ type: "fixedPoint", precision: 2 }} />

					<Column dataField='MainLocalAmount' caption={"Local Amount"} format={{ type: "fixedPoint", precision: 2 }} />

					<Column dataField='OutstandingAmount' format={{ type: "fixedPoint", precision: 2 }} />
				</Listing>
			</div>


		


			<EInvoiceMain 
				formID={currentDetailID} 
				closePopup={closePopup} 
				apiURL={apiURL} 
				title={"E-Invoice Main"} 
				  invoiceType={invType}
				transferData={transferData}
				clearTransfer={setTransferData}
			/>

		
		</Container>
	);
}