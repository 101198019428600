import { useRef, useState, useEffect } from "react";
import Container from "../../components/container";
import { projectOptions, journalTypeOptions, journalTransactionListing } from "../../utils/lookupstore";
import { Button } from "devextreme-react";
import baseapi from "../../api/baseapi";
import { DataGrid, Button as Pager, Toolbar, Column, Selection, TotalItem, Summary, GroupPanel, Grouping, GroupItem } from "devextreme-react/data-grid";
import utils, { loading, closeLoading } from "../../utils/common";
import PathIndicator from "../../components/path-indicator/PathIndicator";
import CustomizedLookup from "../../components/lookup/Lookup";
import { TextBox, NumberBox, SelectBox, DropDownBox, Popover, CheckBox } from "devextreme-react";
import { DateBox } from "devextreme-react/date-box";
import { Popup } from "devextreme-react/popup";
import ErrorPopUpForm from "../../components/popup-form/ErrorPopupForm";
import ARCreditNoteForm from "../AR-Module/credit-notes/form";
import ARDebitNoteForm from "../AR-Module/debit-notes/form";
import ARReceiptForm from "../AR-Module/receipt/form";
import ARSalesForm from "../AR-Module/sales/form";
import APCreditNoteForm from "../AP-Module/credit-notes/form";
import APDebitNoteForm from "../AP-Module/debit-notes/form";
import APPaymentForm from "../AP-Module/payment/form";
import APPurchaseForm from "../AP-Module/purchase/form";
import JournalEntryForm from "../GL-Module/journal-entry/form";
import CashBookEntryForm from "../GL-Module/cash-book-entry/form";
import ContraForm from "../contra/form";
import ARRefundForm from "../AR-Module/refund/form";
import APRefundForm from "../AP-Module/refund/form";
import SalesInvoiceMainForm from "../Sales-Module/invoice-main/form";
import SalesCashSalesForm from "../Sales-Module/cash-sales/form";
import PurchaseInvoiceForm from "../Purchase-Module/invoice/form";
import CashPurchaseForm from "../Purchase-Module/cash-purchase/form";
import { saveToLS, getFromLS } from "../../utils/localstorage";

export default function JournalTransaction() {
	const fromProjectLookupRef = useRef(null);
	const toProjectLookupRef = useRef(null);
	const [fromProjectList, setFromProjectList] = useState([]);
	const [toProjectList, setToProjectList] = useState([]);
	const fromJournalLookupRef = useRef(null);
	const toJournalLookupRef = useRef(null);
	const [fromJournalList, setFromJournalList] = useState([]);
	const [toJournalList, setToJournalList] = useState([]);

	const tempForm = useRef(null);
	const [listingDataSource, setListingDataSource] = useState(null);
	const [dropDownList, setDropDownList] = useState({
		reportList: []
	});
	const [originalDropDownList, setOriginalDropDownList] = useState({});
	const userID = JSON.parse(localStorage.getItem("data"));
	const currentToken = localStorage.getItem("Authorization").substring(7);
	const [fromDate, setFromDate] = useState(
		new Date(new Date().setDate(1)).toLocaleDateString("en-US", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit"
		})
	);
	const [toDate, setToDate] = useState(
		new Date(new Date()).toLocaleDateString("en-US", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit"
		})
	);
	const [paramValue, setParamValue] = useState([
		{
			ParameterName: "FromDate",
			ParameterValue: utils.dateboxFormat(fromDate)
		},
		{
			ParameterName: "ToDate",
			ParameterValue: utils.dateboxFormat(toDate)
		},
		{
			ParameterName: "IsZero",
			ParameterValue: false
		},
		{
			ParameterName: "ReportName",
			ParameterValue: "Journal Of Transaction"
		},
		{
			ParameterName: "ReportID",
			ParameterValue: "Ev3F1g_UBT8%3d"
		}
	]);
	const [openReportPop, setOpenReportPop] = useState(false);
	const [selectedJournal, setSelectedJournal] = useState(0);
	const [openJournalRange, setOpenJournalRange] = useState();
	const [printRepDisabled, setPrintRepDisabled] = useState(true);
	const [openProjectRange, setOpenProjectRange] = useState();
	const [selectedProject, setSelectedProject] = useState(0);
	const [currentEditID, setCurrentEditID] = useState("");
	const [documentDate, setDocumentDate] = useState(null);
	const [documentNo, setDocumentNo] = useState("");
	const [form, setForm] = useState("");
	const storageName = "GLJournalTransactionReportListingColumnWidth";

	const gridRef = useRef(null);
	const searchBoxRef = useRef(null);
	const currentFocusIndex = useRef(null);
	const dropdownRef = useRef(null);
	const popupMessageRef = useRef(null);
	const pagingIndex = useRef({
		loading: false,
		page: 1
	});

	const filterTypes = [
		{
			ID: 0,
			Type: "No Filter"
		},
		{
			ID: 1,
			Type: "Filter By Range"
		},
		{
			ID: 2,
			Type: "Filter By Multi-Select"
		}
	];

	const docType = [
        // AR
		{
			TransType: "RI",
			DocumentType: "Sales"
		},
		{
			TransType: "RR",
			DocumentType: "Receipt"
		},
		{
			TransType: "RD",
			DocumentType: "ARDebitNote"
		},
		{
			TransType: "RC",
			DocumentType: "ARCreditNote"
		},
		{
			TransType: "RRF",
			DocumentType: "ARRefund"
		},
		{
			TransType: "RCT",
			DocumentType: "Contra"
		},
        // AP
		{
			TransType: "PP",
			DocumentType: "Purchase"
		},
		{
			TransType: "PV",
			DocumentType: "Payment"
		},
		{
			TransType: "PC",
			DocumentType: "APCreditNote"
		},
		{
			TransType: "PD",
			DocumentType: "APDebitNote"
		},
		{
			TransType: "PCT",
			DocumentType: "APContra"
		},
		{
			TransType: "PRF",
			DocumentType: "APRefund"
		},
        // SALES
		{
			TransType: "SCS",
			DocumentType: "CashSales"
		},
		{
			TransType: "INV",
			DocumentType: "SalesInvoice"
		},
        // PURCHASE
		{
			TransType: "PPI",
			DocumentType: "PurchaseInvoice"
		},
		{
			TransType: "PCP",
			DocumentType: "CashPurchase"
		},
        // GL
		{
			TransType: "JV",
			DocumentType: "Journal"
		},
		{
			TransType: "GL-PV",
			DocumentType: "GLPayment"
		},
		{
			TransType: "GL-OR",
			DocumentType: "GLReceipt"
		}
	];

	useEffect(() => {
		Promise.allSettled([projectOptions.store.load(), journalTypeOptions.store.load(), journalTransactionListing.store.load()]).then(
			(lists) => {
				setDropDownList({
					projectList: lists[0].value.data,
					journalTypeList: lists[1].value.data,
                    reportList: lists[2].value.data,
				});

				setOriginalDropDownList({
					projectList: lists[0].value.data,
					journalTypeList: lists[1].value.data,
                    reportList: lists[2].value.data,
				});

				// console.log(lists[0].value.data)
			}
		);
	}, []);

	const handlePrintBtn = (e) => {
		if (returnSelectedValue("ReportID") !== null) {
			baseapi.httpget(`${utils.ReportURL}/api/Report/getReportDetailsByReportName?reportID=${returnSelectedValue("ReportID")}&accessToken=${currentToken}`).then((response) => {
				const { data } = response;
				data.map((items) => {
					confirmReportParameters(items.ID, items.ViewerAccessURL);
				});
			});
		}
	};

	const confirmReportParameters = async (reportID, ViewerAccessURL) => {
		var documentViewerLink = ViewerAccessURL;

		try {
			// const existingParamArr = [];
			for (const param of paramValue) {
				switch (param.ParameterName) {
					case "FromDate":
					case "ToDate":
					case "FromProject":
					case "ToProject":
					case "FromJournal":
					case "ToJournal":
						documentViewerLink += `&${param.ParameterName}=${param.ParameterValue}`;
						break;
					case "JournalList":
					case "ProjectList":
						documentViewerLink += `&${param.ParameterName}=${listingJoin(param.ParameterValue)}`;
						break;
				}
			}
			// console.log(existingParamArr);

			window.open(documentViewerLink, "_blank");
		} catch (error) {
			console.error("Error fetching report parameters:", error);
		}
	};

	function listingJoin (listValue) {
		return listValue.replace("$%", ",");
	}

	const onInitialized = () => {
		var parameters = `?userID=${userID?.userID}&reportName=${returnSelectedValue("ReportName")}`;
		loading();

		tempForm.current = [...paramValue];

		tempForm.current.forEach((param) => {
			if (param.ParameterName.includes("List") && Array.isArray(param.ParameterValue)) {
				param.ParameterValue = param.ParameterValue.join("$%");
			}

			if (typeof param.ParameterValue !== "string") {
				param.ParameterValue = String(param.ParameterValue);
			}
		});

		var copied = tempForm.current;
		// console.log(copied);

		baseapi
			.httppost(`${utils.ReportURL}/api/Report/JournalTransaction${parameters}`, copied)
			.then((response) => {
				const { data } = response;
				// console.log(data);
				if (data !== null && data !== undefined) {
					var mainDataArray = [];
					// data.map((e) => {
					// 	console.log(e.DocumentID !== "");
					// });

					for (var i = 0; i < data.length; i++) {
						// if (data[i].DocumentID !== "")
						mainDataArray.push(data[i]);
					}
					setListingDataSource(mainDataArray);

					if (data.length > 0) {
						setPrintRepDisabled(false);
					}

					// tempForm.current.forEach((param) => {
					// 	if (param.ParameterName === "IsZero") {
					// 		param.ParameterValue = param.ParameterValue === "true";
					// 	}
					// });
				}

				if (data.status) {
					utils.displayPopupMessage(popupMessageRef, {
						visible: true,
						message: data.message,
						type: "Danger"
					});
				}

				closeLoading();
			})
			.catch((e) => {
				// closeLoading();
				console.log(e);
			});

		tempForm.current.forEach((param) => {
			if (param.ParameterName === "IsZero") {
				param.ParameterValue = param.ParameterValue === "true";
			}
		});
	};

	useEffect(() => {
		if (pagingIndex.current["loading"] === true && Array.isArray(dropDownList)) {
			pagingIndex.current["page"] += 1;
			pagingIndex.current["loading"] = false;
		}

		// if (Array.isArray(listingDataSource)) {
		// 	previousDataSource.current = [...listingDataSource];
		// }
	}, [dropDownList]);

	function valueOnChange(e, type = null) {
		try {
			if (e.value !== undefined) {
				const dataField = e.element.getAttribute("dataField");
				setPrintRepDisabled(true);
				if (dataField === "FromDate") {
					setFromDate(utils.dateboxFormat(e.value));
				} else if (dataField === "ToDate") {
					setToDate(utils.dateboxFormat(e.value));
				} else if (dataField === "FilterNumber") {
					if (e.value === 1) {
						switch (type) {
							case "Project":
								setSelectedProject(e.value);
								setOpenProjectRange(true);
								break;
							case "Journal":
								setSelectedJournal(e.value);
								setOpenJournalRange(true);
								break;
						}
					}
					if (e.value === 2) {
						switch (type) {
							case "Project":
								setSelectedProject(e.value);
								break;
							case "Journal":
								setSelectedJournal(e.value);
								break;
						}
					}
				}

				if (dataField !== "FilterNumber") updateParamValue(dataField, e.value);
			}
		} catch (err) {
			console.log(err);
		}
	}

	const updateParamValue = (key, value) => {
		setParamValue((prevParamValue) => {
			const updatedParamValue = [...prevParamValue]; // Create a copy of the previous state array

			// Find the index of the item with the matching key
			const index = updatedParamValue.findIndex((item) => item.ParameterName === key);

			if (index !== -1) {
				// Update the existing item if it exists
				updatedParamValue[index] = { ParameterName: key, ParameterValue: value };
			} else {
				// Add a new item if the key doesn't exist
				updatedParamValue.push({ ParameterName: key, ParameterValue: value });
			}

			return updatedParamValue;
		});
	};

	const removeParamValue = (key) => {
		setParamValue((prevParamValue) => {
			// Filter out the item with the matching ParameterName
			var updatedParamValue = null;
			updatedParamValue = prevParamValue.filter((item) => item.ParameterName !== key);
			return updatedParamValue;
		});
	};

	const returnSelectedKeys = (type) => {
		const paramObject = paramValue.reduce((acc, curr) => {
			acc[curr.ParameterName] = curr.ParameterValue;
			return acc;
		}, {});

		let selectedKeys = [];

		if (type === "Project" && paramObject["ProjectList"]) {
			selectedKeys = ensureArray(paramObject["ProjectList"]);
		} else if (type === "Journal" && paramObject["JournalList"]) {
			selectedKeys = ensureArray(paramObject["JournalList"]);
		} 

		return selectedKeys;
	};

	const ensureArray = (value) => {
		if (Array.isArray(value)) {
			return value;
		} else {
			return value.split("$%");
		}
	};

	const returnSelectedValue = (type) => {
		const filtered = paramValue.filter((x) => x.ParameterName === type);
		return filtered.length > 0 ? filtered[0].ParameterValue : null;
	};

	const returnDropDownList = (type) => {
		if (type === "Project") {
			return dropDownList.projectList;
		} else if (type === "Journal") {
			return dropDownList.journalTypeList;
		} 
	};

	function DataGridMultiRender(type) {
		let selectedKeys = returnSelectedKeys(type);
		// console.log(type);

		return (
			<div className='customized-lookup-container'>
				<div className='customized-lookup-search-container'>
					<div>
						<TextBox
							ref={searchBoxRef}
							placeholder='Search...'
							width={250}
							valueChangeEvent='keyup'
							showClearButton={true}
							onValueChanged={(e) => {
								gridRef.current.instance.searchByText(e.value);
								updateDropDownList(e.value, type, pagingIndex.current["page"], false);
							}}
							onKeyDown={(e) => {
								// console.log("key down", e.event)
								if (e.event.key === "ArrowDown") {
									gridRef.current.instance.focus();
								}
							}}
						/>
					</div>

					<div className='customized-lookup-btn-section'>
						<Button
							text='Clear'
							onClick={(e) => {
								if (searchBoxRef.current !== null) searchBoxRef.current.instance.reset();
								// refreshLookupData(type);
							}}
						/>
					</div>

					<div>
						<Button
							style={{ marginLeft: "5px" }}
							text='Clear Filter'
							stylingMode='text'
							type='default'
							onClick={() => {
								if (type === "Project") {
									removeParamValue("ProjectList");
									removeParamValue("FromProject");
									removeParamValue("ToProject");
									setSelectedProject(0);
								} else if (type === "Journal") {
									setSelectedJournal(0);
									removeParamValue("JournalList");
									removeParamValue("FromJournal");
									removeParamValue("ToJournal");
								}
								refreshLookupData(type);
							}}
							elementAttr={{ dataField: "FilterNumber" }}
						/>
					</div>
				</div>
				<DataGrid
					ref={gridRef}
					height={200}
					className={"lookup-datagrid gl-listing-datagrid"}
					showBorders={true}
					dataSource={returnDropDownList(type)}
					scrolling={{ columnRenderingMode: "standard", showScrollbar: "onHover" }}
					columnChooser={{ enabled: false }}
					allowColumnResizing={true}
					allowColumnReordering={true}
					hoverStateEnabled={true}
					paging={{ enabled: false }}
					keyExpr='code'
					focusedRowEnabled={true}
					focusedRowKey={selectedKeys}
					selectedRowKeys={selectedKeys}
					onSelectionChanged={(e) => dataGridOnSelectionChanged(e, type)}
					onContentReady={(element) => {
						const scrollable = element.component.getScrollable();
						if (scrollable !== undefined) {
							scrollable.on("scroll", function (e) {
								if (e.reachedBottom) {
									if (pagingIndex.current["loading"] === false) {
										// console.log("page", pagingIndex.current["page"]);
										pagingIndex.current["loading"] = true;
										updateDropDownList("", type, pagingIndex.current["page"], true);
										// console.log(e);
									}
								}
							});
						}
					}}
					onKeyDown={(e) => {
						// if(e.event.key === "Enter"){
						//     setDropdownOpened(false);
						// }
						if (e.event.key === "ArrowUp") {
							// If focus is one the first row then brings focus back to the search box
							if (currentFocusIndex.current === 0) {
								if (type === "Project") {
									removeParamValue("ProjectList");
								} else if (type === "Journal") {
									removeParamValue("JournalList");
								}
								refreshLookupData(type);
							}
						}
					}}
					onFocusedRowChanging={(e) => {
						if (e.event === null) {
							e.cancel = true;
						} else if (e.event.key === undefined) {
							e.cancel = true;
						}
					}}
					// onFocusedRowChanged={(e) => {
					//   const data = e.row.data;
					//   currentFocusIndex.current = e.rowIndex;
					//   if(e.event.key === "Enter") {
					//     setSelectedCustKey(data.id);
					//   }
					// }}
				>
					<Column
						dataField='code'
						elementAttr={{
							dataField: type === "Journal" ? "JournalCode" : "ProjectCode"
						}}
						defaultSortOrder={"asc"}
						caption={type === "Journal" ? "Journal Code" : "Project Code"}
						visible={true}
						alignment='left'
						allowGrouping={false}
					/>

					<Column
						dataField='name'
						elementAttr={{dataField: type === "Journal" ? "JournalName" : "ProjectName"}}
						caption={type === "Journal" ? "Journal Name" : "Project Name" }
						visible={true}
						alignment='left'
						allowGrouping={false}
					/>

					<Selection mode='multiple' showSelectAll={true} selectAllMode='page' showCheckBoxesMode={"always"} />
					<Toolbar visible={false} />
				</DataGrid>
			</div>
		);
	}

	const dataGridOnSelectionChanged = (e, type) => {
		if (type === "Project") {
			updateParamValue("ProjectList", e.selectedRowKeys);
		} else if (type === "Journal") {
			updateParamValue("JournalList", e.selectedRowKeys);
		} 
	};

	const popFunction = (e, type) => {
		if (e.cancel === false) {
			if (type === "Project") {
				setOpenProjectRange(false);
			} else if (type === "Journal") {
				setOpenJournalRange(false);
			}
		} else {
			if (type === "Project") {
				setOpenProjectRange(true);
			} else if (type === "Journal") {
				setOpenJournalRange(true);
			} 
		}
	};

	const returnDropDownListURL = (type) => {
		if (type === "Journal") {
			return "api/Utilities/GetJournalTypes";
		} else if (type === "Project") {
			return "api/Utilities/GetProjects";
		}
	};

	function refreshLookupData(type) {
		if (type === "Journal") {
			setDropDownList((prevState) => ({
				...prevState,
				journalTypeList: [...originalDropDownList.journalTypeList]
			}));
		} else if (type === "Project") {
			setDropDownList((prevState) => ({
				...prevState,
				projectList: [...originalDropDownList.projectList]
			}));
		}
	}

	const arrayToString = (arr) => {
		if (Array.isArray(arr)) {
			return arr.join(" ");
		} else {
			return arr;
		}
	};

	const updateDropDownList = (paramValue, type, page, paging) => {
		const typeToArrayNameMap = {
			Journal: "journalTypeList",
			Project: "projectList"
		};
		// console.log(paramValue);
		var value = "";

		if (!paging) {
			page = 0;
		}

		if (paramValue !== undefined) {
			value = paramValue;
		}

		const arrayName = typeToArrayNameMap[type];
		const query = value.length === 0 ? `${paramValue}` : `${paramValue} ${arrayToString(returnSelectedKeys(type))}`;

        baseapi
            .httpget(returnDropDownListURL(type), { q: query, singleReturn: true, page: page })
            .then((response) => {
                const data = response.data;
                // console.log(data);
                setDropDownList((prevState) => {
                    const existingArray = prevState[arrayName] || [];
                    const newData = data.filter((newItem) => !existingArray.some((existingItem) => existingItem.id === newItem.id));
                    return {
                        ...prevState,
                        [arrayName]: [...existingArray, ...newData]
                    };
                });
            })
            .catch(() => {
                throw "Network error";
            });
	};

	useEffect(() => {
		const toDateObj = new Date(toDate);
		const fromDateObj = new Date(fromDate);

		if (fromDateObj > toDateObj) {
			utils.displayPopupMessage(popupMessageRef, {
				visible: true,
				message: "To Date cannot be earlier than From Date.",
				type: "Warning"
			});

			const setDate = new Date(); // current date

			if (fromDateObj > setDate) {
				setToDate(utils.dateboxFormat(fromDate));
			} else {
				setToDate(utils.dateboxFormat(setDate));
			}
		}
	}, [toDate, fromDate]);

	function getDocTransCode(documentNo) {
		return baseapi.httpget("/api/Utilities/CheckDocumentNoExist", { documentNo: documentNo || "" }).then((response) => {
			const { data } = response;
			if (data.length > 0) {
				// console.log(data[0]);
				return data[0];
			} else {
				return null;
			}
		});
	}

	const onRowPrepared = (e) => {
		if (e.rowType === "data") {			
			if(!utils.isNullOrEmpty(e.data["canExpand"])) {
				if(!e.data["canExpand"]) {
					e.cells[0].cellElement.classList.remove('dx-datagrid-expand');  
					  e.cells[0].cellElement.childNodes[0].classList.remove('dx-datagrid-group-closed');  
				}
			}
		}

		if (e.rowType === "header" && storageName !== null) {
			const find = e.columns.find((c) => c.command === "transparent");
			//Ignore the fixed columns
			if (utils.isNullOrEmpty(find)) {
				const columns = e.columns;
				const previousRecord = getFromLS(storageName);

				if (previousRecord === undefined || previousRecord.length !== columns.length) {
					for (var i = 0; i < columns.length; i++) {
						columns[i]["columnWidth"] = null;
					}
				} else {
					for (var i = 0; i < columns.length; i++) {
						columns[i]["columnWidth"] = previousRecord[i]["columnWidth"];
					}
				}
				saveToLS(storageName, columns);
			}
		}
	};

	const onOptionChanged = (e) => {
		if (e.name === "columns" && e.fullName.includes("width") && storageName !== null) {
			//Sample format : columns[0].width
			const columns = getFromLS(storageName);
			const columnIndex = parseInt(e.fullName.substring(8, 9));
			const arrayIndex = columns.findIndex((x) => x.index === columnIndex);

			// Save the new width
			columns[arrayIndex]["columnWidth"] = e.value;
			saveToLS(storageName, columns);
		}
	};

	const columns = [
		{ dataField: "JournalTypeName", groupIndex: 0, caption: "Journal Type" },
		{ dataField: "DocumentDate", caption: "Date", dataType: "date", format: "dd/MM/yyyy" },
		{
		  dataField: "DocumentNo",
		  caption: "Doc No",
		  cellRender: (e) => {
			const data = e.data;
			const id = data.DocumentID;
			const nonIntegerRegex = /[a-zA-Z-]+/g;
	  
			if (data.DocumentNo !== undefined) {
			  return (
				<span
				  className="clickable-span-tag"
				  onClick={() => {
					getDocTransCode(data.DocumentNo).then((res) => {
					  let valueToCheck = "";
	  
					  if (res === null) {
						setCurrentEditID(id);
						valueToCheck = data.TransactionTypeCode.trim().match(nonIntegerRegex);
					  } else {
						setCurrentEditID(res.DocumentID);
						valueToCheck = res.transactionCode.trim().match(nonIntegerRegex);
					  }
	  
					  valueToCheck = valueToCheck ? valueToCheck.join("") : "";
	  
					  docType.find((item) => {
						if (item.TransType === valueToCheck) {
						  setForm(item.DocumentType);
						  setDocumentDate(data.DocumentDate);
						  setDocumentNo(data.DocumentNo);
						}
					  });
					});
				  }}
				>
				  {e.displayValue}
				</span>
			  );
			} else {
			  return e.displayValue;
			}
		  },
		},
		{ dataField: "ChartCode" },
		{ dataField: "GLDescription", caption: "GL Desc" },
		{ dataField: "Description", caption: "Desc" },
		{ dataField: "Project" },
		{ dataField: "ForexCode", caption: "Forex" },
		{
		  dataField: "Debit",
		  alignment: "center",
		  caption: "Debit",
		  dataType: "number",
		  customizeText: (e) => utils.numberToCurrency(e.value),
		},
		{
		  dataField: "Credit",
		  alignment: "center",
		  caption: "Credit",
		  dataType: "number",
		  customizeText: (e) => utils.numberToCurrency(e.value),
		},
		{
		  dataField: "LocalDebit",
		  alignment: "center",
		  caption: "Local DR",
		  dataType: "number",
		  customizeText: (e) => utils.numberToCurrency(e.value),
		},
		{
		  dataField: "LocalCredit",
		  alignment: "center",
		  caption: "Local CR",
		  dataType: "number",
		  customizeText: (e) => utils.numberToCurrency(e.value),
		},
		{ dataField: "TransactionTypeCode", caption: "Doc Type" },
	];	  

	return (
		<Container>
			<div className='listing-page-title-container'>
				<span className='datagrid-customized-title'>
					<PathIndicator />
				</span>
			</div>
			<Popup visible={openReportPop} width={"auto"} height={"auto"} dragEnabled={false} title='Print Report' showCloseButton={false}>
				<div className='popup-group-form-item'>
					<div className='popup-group-form-label'>Report</div>
					<SelectBox
						value={returnSelectedValue("ReportID")}
						elementAttr={{ dataField: "ReportID" }}
						onValueChanged={(e) => {
							// console.log(e.value);
							updateParamValue("ReportID", e.value);
						}}
						alignment='left'
						width={"450px"}
						dataSource={dropDownList.reportList}
						displayExpr={"code"}
						valueExpr={"id"}
						searchEnabled={true}
						showClearButton={true}
					/>
				</div>
				<div className='popup-group-form-item' style={{ marginTop: "15px", justifyContent: "flex-end" }}>
					<Button text='Cancel' type='normal' width={"auto"} style={{ marginLeft: "20px" }} onClick={(e) => setOpenReportPop(false)} />
					<Button text='Print Report' type='success' width={"auto"} style={{ marginLeft: "20px" }} onClick={(e) => handlePrintBtn(e)} />
				</div>
			</Popup>

			<div style={{ backgroundColor: "#EFEFEF", borderRadius: "15px", width: "85%", padding: "0.7%" }}>
				<div className='popup-form-item-container2'>
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'>Date</div>
						<DateBox
							type='date'
							value={fromDate}
							elementAttr={{ dataField: "FromDate" }}
							useMaskBehavior={true}
							label='From'
							labelMode='floating'
							displayFormat='dd/MM/yyyy'
							dateSerializationFormat='yyyy-MM-dd'
							onValueChanged={(e) => {
								valueOnChange(e, "FromDate");
							}}
						/>

						<DateBox
							type='date'
							value={toDate}
							elementAttr={{ dataField: "ToDate" }}
							useMaskBehavior={true}
							label='To'
							labelMode='floating'
							displayFormat='dd/MM/yyyy'
							dateSerializationFormat='yyyy-MM-dd'
							onValueChanged={(e) => {
								valueOnChange(e, "ToDate");
							}}
							style={{ marginLeft: "5%" }}
						/>
					</div>
				</div>

				<div className='popup-form-item-container2'>
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'	style={{ marginTop: "7px" }}> Journal </div>
						{selectedJournal === 0 && (
							<SelectBox
								width={"250px"}
								dataSource={filterTypes}
								value={selectedJournal}
								displayExpr={"Type"}
								valueExpr={"ID"}
								elementAttr={{ dataField: "FilterNumber" }}
								onValueChanged={(e) => {
									valueOnChange(e, "Journal");
								}}
							/>
						)}
						{selectedJournal === 1 && (
							<div onClick={() => setOpenJournalRange(true)}>
								<TextBox
									value={returnSelectedValue("FromJournal") || returnSelectedValue("ToJournal") ? `${returnSelectedValue("FromJournal")} to ${returnSelectedValue("ToJournal")}` : "No Range Selected Yet"}
									// useMaskBehavior={true} // Enable mask behavior
									// mask="cccccc" // Define the input mask
									id='Journal'
									style={{ marginRight: "10px" }}
									width={"250px"}>
									<Popover
										target='#Journal'
										showEvent={"click"}
										// hideEvent="mouseleave"
										position='bottom'
										visible={openJournalRange}
										onHiding={(e) => popFunction(e, "Journal")}
										dragEnabled={false}
										hideOnOutsideClick={true}
										width={500}
										height={125}>
										<div>
											<div className='popup-form-item-container2'>
												<div>
													<label>From</label>
													<CustomizedLookup
														ref={fromJournalLookupRef}
														className={"ar-listing-datagrid"}
														dataSource={fromJournalList}
														displayExpr={"code"}
														valueExpr={"code"}
														value={returnSelectedValue("FromJournal")}
														onSelectionChanged={(e) => {
															updateParamValue("FromJournal", e.value);
														}}
														dataSourceURL={"api/Utilities/GetJournalTypes"}
														displayText={returnSelectedValue("FromJournal")}
														onDataSourceChanged={setFromJournalList}>
														<Column dataField='code' elementAttr={{ dataField: "JournalCode" }} visible={true} caption='Journal Code' alignment='left' allowGrouping={false} />
														<Column dataField='name' elementAttr={{ dataField: "JournalName" }} visible={true} caption='Journal Name' alignment='left' allowGrouping={false} />
													</CustomizedLookup>
												</div>
												<div>
													<label>To</label>

													<CustomizedLookup
														ref={toJournalLookupRef}
														className={"ar-listing-datagrid"}
														dataSource={toJournalList}
														displayExpr={"code"}
														valueExpr={"code"}
														value={returnSelectedValue("ToJournal")}
														onSelectionChanged={(e) => {
															updateParamValue("ToJournal", e.value);
														}}
														dataSourceURL={"api/Utilities/GetJournalTypes"}
														displayText={returnSelectedValue("ToJournal")}
														onDataSourceChanged={setToJournalList}>
														<Column dataField='code' elementAttr={{ dataField: "JournalCode" }} visible={true} caption='Journal Code' alignment='left' allowGrouping={false} />
														<Column dataField='name' elementAttr={{ dataField: "JournalName" }} visible={true} caption='Journal Name' alignment='left' allowGrouping={false} />
													</CustomizedLookup>
												</div>
											</div>
											<div>
												<Button
													style={{ position: "absolute", top: "65%", left: "75%" }}
													text='Clear Filter'
													stylingMode='text'
													type='default'
													onClick={() => {
														removeParamValue("JournalList");
														removeParamValue("FromJournal");
														removeParamValue("ToJournal");
														setSelectedJournal(0);
													}}
													elementAttr={{ dataField: "FilterNumber" }}
												/>
											</div>
										</div>
									</Popover>
								</TextBox>
							</div>
						)}
						{selectedJournal === 2 && (
							<div>
								<TextBox
									value={returnSelectedKeys("Journal").length !== 0 ? `${returnSelectedKeys("Journal").length} Selected  (${returnSelectedKeys("Journal")})` : `${returnSelectedKeys("Journal").length} Selected`}
									style={{ marginTop: "-35px" }}
									height={0}
									showClearButton={false}
									width={"250px"}>
									<DropDownBox
										ref={dropdownRef}
										// opened={openSalesSpecific}
										// onClosed={(e) => closeDropDownBox(e, "Journal")}
										valueExpr='id'
										deferRendering={false}
										displayExpr='code'
										showClearButton={false}
										contentRender={() => DataGridMultiRender("Journal")}
										defaultOpened={true}
										width={"250px"}
										style={{ marginTop: "-2.5px" }}
										dropDownOptions={{ width: "auto" }}
									/>
								</TextBox>
							</div>
						)}
					</div>
				</div>

                <div className='popup-form-item-container2'>
                    <div className='popup-group-form-item'>
                        <div className='popup-group-form-label'	style={{ marginTop: "7px" }}> Project </div>
                        {selectedProject === 0 && (
                            <SelectBox
                                width={"250px"}
                                dataSource={filterTypes}
                                value={selectedProject}
                                displayExpr={"Type"}
                                valueExpr={"ID"}
                                elementAttr={{ dataField: "FilterNumber" }}
                                onValueChanged={(e) => {
                                    valueOnChange(e, "Project");
                                }}
                            />
                        )}
                        {selectedProject === 1 && (
                            <div onClick={() => setOpenProjectRange(true)}>
                                <TextBox
                                    value={returnSelectedValue("FromProject") || returnSelectedValue("ToProject") ? `${returnSelectedValue("FromProject")} to ${returnSelectedValue("ToProject")}` : "No Range Selected Yet"}
                                    // useMaskBehavior={true} // Enable mask behavior
                                    // mask="cccccc" // Define the input mask
                                    id='Project'
                                    style={{ marginRight: "10px" }}
                                    width={"250px"}>
                                    <Popover
                                        target='#Project'
                                        showEvent={"click"}
                                        // hideEvent="mouseleave"
                                        position='bottom'
                                        visible={openProjectRange}
                                        onHiding={(e) => popFunction(e, "Project")}
                                        dragEnabled={false}
                                        hideOnOutsideClick={true}
                                        width={500}
                                        height={125}>
                                        <div>
                                            <div className='popup-form-item-container2'>
                                                <div>
                                                    <label>From</label>
                                                    <CustomizedLookup
                                                        ref={fromProjectLookupRef}
                                                        className={"ar-listing-datagrid"}
                                                        dataSource={fromProjectList}
                                                        displayExpr={"code"}
                                                        valueExpr={"code"}
                                                        value={returnSelectedValue("FromProject")}
                                                        onSelectionChanged={(e) => {
                                                            updateParamValue("FromProject", e.value);
                                                        }}
                                                        dataSourceURL={"api/Utilities/GetProjects"}
                                                        displayText={returnSelectedValue("FromProject")}
                                                        onDataSourceChanged={setFromProjectList}>
                                                        <Column dataField='code' elementAttr={{ dataField: "ProjectCode" }} visible={true} caption='Project Code' alignment='left' allowGrouping={false} />
                                                        <Column dataField='name' elementAttr={{ dataField: "ProjectName" }} visible={true} caption='Project Name' alignment='left' allowGrouping={false} />
                                                    </CustomizedLookup>
                                                </div>
                                                <div>
                                                    <label>To</label>

                                                    <CustomizedLookup
                                                        ref={toProjectLookupRef}
                                                        className={"ar-listing-datagrid"}
                                                        dataSource={toProjectList}
                                                        displayExpr={"code"}
                                                        valueExpr={"code"}
                                                        value={returnSelectedValue("ToProject")}
                                                        displayText={returnSelectedValue("ToProject")}
                                                        onSelectionChanged={(e) => {
                                                            updateParamValue("ToProject", e.value);
                                                        }}
                                                        dataSourceURL={"api/Utilities/GetProjects"}
                                                        onDataSourceChanged={setToProjectList}>
                                                        <Column dataField='code' elementAttr={{ dataField: "ProjectCode" }} visible={true} caption='Project Code' alignment='left' allowGrouping={false} />
                                                        <Column dataField='name' elementAttr={{ dataField: "ProjectName" }} visible={true} caption='Project Name' alignment='left' allowGrouping={false} />
                                                    </CustomizedLookup>
                                                </div>
                                            </div>
                                            <div>
                                                <Button
                                                    style={{ position: "absolute", top: "65%", left: "75%" }}
                                                    text='Clear Filter'
                                                    stylingMode='text'
                                                    type='default'
                                                    onClick={() => {
                                                        removeParamValue("ProjectList");
                                                        removeParamValue("FromProject");
                                                        removeParamValue("ToProject");
                                                        setSelectedProject(0);
                                                    }}
                                                    elementAttr={{ dataField: "FilterNumber" }}
                                                />
                                            </div>
                                        </div>
                                    </Popover>
                                </TextBox>
                            </div>
                        )}
                        {selectedProject === 2 && (
                            <div>
                                <TextBox
                                    value={returnSelectedKeys("Project").length !== 0 ? `${returnSelectedKeys("Project").length} Selected  (${returnSelectedKeys("Project")})` : `${returnSelectedKeys("Project").length} Selected`}
                                    style={{ marginTop: "-35px" }}
                                    height={0}
                                    showClearButton={false}
                                    width={"250px"}>
                                    <DropDownBox
                                        // onClosed={(e) => closeDropDownBox(e, "Project")}
                                        ref={dropdownRef}
                                        // opened={openCustSpecific}
                                        valueExpr='id'
                                        deferRendering={false}
                                        displayExpr='code'
                                        showClearButton={false}
                                        contentRender={(e) => DataGridMultiRender("Project")}
                                        defaultOpened={true}
                                        width={"250px"}
										style={{ marginTop: "-2.5px" }}
                                        dropDownOptions={{ width: "auto" }}
                                    />
                                </TextBox>
                            </div>
                        )}
                    </div>
				</div>

				<div className='popup-form-item-container2'>
					<div className='popup-group-form-item' style={{ alignItems: "center" }}>
						<CheckBox
							value={returnSelectedValue("IsZero")}
							defaultValue={false}
							text='Show Zero Balance'
							onValueChanged={(e) => {
								updateParamValue("IsZero", e.value);
							}}
						/>
					</div>
				</div>
			</div>

			<div className='popup-group-form-item' style={{ marginTop: "0.5%" }}>
				<Button text='Inquiry' type='success' width={"auto"} onClick={(e) => onInitialized(e)} />
				<Button text='Print Report' disabled={printRepDisabled} type='success' stylingMode={"outlined"} width={"auto"} style={{ marginLeft: "20px" }} onClick={(e) => setOpenReportPop(true)} />
			</div>

			<DataGrid dataSource={listingDataSource} keyExpr={"DocumentID"} allowColumnReordering={false} showBorders={true} width='100%' paging={false} allowColumnResizing={true}
				onRowPrepared={(e) => onRowPrepared(e)}
				onOptionChanged={onOptionChanged}
			>
				<GroupPanel visible={false} />
				<Grouping autoExpandAll={true} />
				{Array.isArray(columns) && columns.map((column) => {
					if (column && column.dataField) {
						const columnWidth = !utils.isNullOrEmpty(column.width) ? column.width : utils.getColumnWidth(column.dataField, storageName) ?? undefined;
						const columnIndex = utils.getColumnVisibleIndex(column.dataField, storageName) ?? 0;
						const columnEditorOptions = column.editorOptions || {};

						// Dynamic grid properties
						const gridProps = {
							...column, // Spread existing properties
							width: columnWidth,
							visibleIndex: columnIndex,
							editorOptions: columnEditorOptions,
						};

						// Default handling for all other columns
						return <Column key={column.dataField} {...gridProps} />;
						}
						return null; // Handle invalid columns
					})
				}
				<Summary>
					<GroupItem
						column='DocumentDate'
						customizeText={(e) => {
							return "Total";
						}}
						showInGroupFooter={true}
					/>
					<GroupItem
						column='Debit'
						summaryType='sum'
						customizeText={(e) => {
							return utils.numberToCurrency(e.value);
						}}
						showInGroupFooter={true}
					/>
					<GroupItem
						column='Credit'
						summaryType='sum'
						customizeText={(e) => {
							return utils.numberToCurrency(e.value);
						}}
						showInGroupFooter={true}
					/>
					<GroupItem
						column='LocalDebit'
						summaryType='sum'
						customizeText={(e) => {
							return utils.numberToCurrency(e.value);
						}}
						showInGroupFooter={true}
					/>
					<GroupItem
						column='LocalCredit'
						summaryType='sum'
						customizeText={(e) => {
							return utils.numberToCurrency(e.value);
						}}
						showInGroupFooter={true}
					/>
				</Summary>
			</DataGrid>

			<ErrorPopUpForm ref={popupMessageRef} />

			<div>
                {/*AR*/}
				{form === "Sales" && <ARSalesForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Invoice Entry"} />}
				{form === "ARDebitNote" && <ARDebitNoteForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Debit Note Entry"} />}
				{form === "Receipt" && <ARReceiptForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Receipt Entry"} />}
				{form === "ARCreditNote" && <ARCreditNoteForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Credit Note Entry"} />}
				{form === "ARRefund" && <ARRefundForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Refund Entry"} />}
				{form === "Contra" && <ContraForm formType={"Customer"} formID={currentEditID} closePopup={setCurrentEditID} openedBefore={true} documentNo={documentNo} documentDate={documentDate} />}

                {/*AP*/}
				{form === "APDebitNote" && <APDebitNoteForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Debit Note Entry"} />}
				{form === "Payment" && <APPaymentForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Payment Entry"} />}
				{form === "Purchase" && <APPurchaseForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Purchase Entry"} />}
				{form === "APCreditNote" && <APCreditNoteForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Credit Note Entry"} />}
				{form === "APContra" && <ContraForm formType={"Supplier"} formID={currentEditID} closePopup={setCurrentEditID} openedBefore={true} documentNo={documentNo} documentDate={documentDate} />}
				{form === "APRefund" && <APRefundForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Refund Entry"} />}

                {/*SALES*/}
				{form === "SalesInvoice" && <SalesInvoiceMainForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Invoice Entry"} />}
				{form === "CashSales" && <SalesCashSalesForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Cash Sales Entry"} />}

                {/*PURCHASE*/}
				{form === "CashPurchase" && <CashPurchaseForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={true} title={"Cash Purchase Entry"} />}
				{form === "PurchaseInvoice" && <PurchaseInvoiceForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Invoice Entry"} />}

                {/*GL*/}
				{form === "Journal" && <JournalEntryForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Journal Entry"} />}
				{form === "GLPayment" && <CashBookEntryForm formType={"Payment Voucher"} formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Payment Voucher"} />}
				{form === "GLReceipt" && <CashBookEntryForm formType={"Official Receipt"} formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Official Receipt"} />}
			</div>
		</Container>
	);
}
