import React, { useEffect, useState, useRef } from "react";
import { Column, Lookup } from "devextreme-react/data-grid";
import { forexOptions } from "../../../utils/lookupstore";
import SalesDeliveryOrderForm from "./form";
import Container from "../../../components/container";
import Listing from "../../../components/listing/newListing";
import utils from "../../../utils/common";
import DropDownButton from "devextreme-react/drop-down-button";
import Transform from "../transform/Transform";

export default function SalesDeliveryOrderListing() {
	const apiURL = `/api/SalesDeliveryOrder`;
	const [dropDownList, setDropDownList] = useState({
		forexList: []
	});
	const [currentEditID, setCurrentEditID] = useState(null);
	const transformRef = useRef(null);
	const [transformTitle, setTransformTitle] = useState(null);
	const [transferData, setTransferData] = useState(null);

	useEffect(() => {
		Promise.allSettled([forexOptions.store.load()]).then((lists) => {
			setDropDownList({
				forexList: lists[0].value.data
			});
		});
	}, []);

	function handleEdit(e) {
		const id = e.row.key;
		setCurrentEditID(id);
	}

	const onItemClick = (e) => {
		const { action } = e.itemData;
		setTransformTitle(action);
		utils.transformOpen(transformRef);
	};

	const transferFormData = (data) => {
        setCurrentEditID("new");
        setTransferData(data);
    };

	const closePopup = (value) => {
		setCurrentEditID(value);
		setTransferData(null);
	};

	return (
		<Container>
			<div id='normal-listing'>
				<Listing
					className='sales-listing-datagrid'
					apiURL={apiURL}
					listingURL='/api/SalesDeliveryOrder/list'
					sortColumn={"DocumentDate"}
					sortOrder={"desc"}
					listingTitle={"Delivery Order"}
					storageName={"SalesDeliveryOrderListingColumnWidth"}
					onAddClick={setCurrentEditID}
					handleEdit={handleEdit}
					customizedAddButton={
						<DropDownButton
							className='customized-listing-add-btn sales-listing-btn'
							splitButton={true}
							useSelectMode={false}
							icon='add'
							text='Add'
							items={[
								{ id: 1, action: "Transfer from Quotation" },
								{ id: 2, action: "Transfer from Sales Order" }
							]}
							displayExpr='action'
							keyExpr='id'
							width={"100px"}
							dropDownOptions={{ width: "180px" }}
							onItemClick={onItemClick}
							onButtonClick={() => {
								setCurrentEditID("new");
							}}
						/>
					}>
					<Column dataField='DocumentDate' format='dd/MM/yyyy' dataType={"date"} />
					<Column dataField='DocumentNo' />
					<Column dataField='CustomerCode' />
					<Column dataField='CustomerName' />
					<Column dataField="ForexCode" caption="Forex"/>
					<Column dataField='MainForexAmount' caption={"Amount"} format={{ type: "fixedPoint", precision: 2 }} />
					<Column dataField='MainLocalAmount' caption={"Local Amount"} format={{ type: "fixedPoint", precision: 2 }} />
					<Column dataField='OutstandingAmount' format={{ type: "fixedPoint", precision: 2 }} />
				</Listing>
			</div>

			<SalesDeliveryOrderForm 
				formID={currentEditID} 
				closePopup={closePopup} 
				apiURL={apiURL} 
				title={"Delivery Order"}
				transferData={transferData}
				clearTransfer={setTransferData}
			/>

			<Transform 
				ref={transformRef} 
				title={transformTitle} 
				apiPath={{
					"Transfer from Quotation": {
						FullDocumentListing: "api/SalesTransfer/QuotationFullDocument",
						PartialDocumentListing: "api/SalesTransfer/QuotationPartial",
						FullDocumentTransfer: "api/SalesTransfer/QuotationFullTransfer",
						PartialDocumentTransfer:  "api/SalesTransfer/QuotationPartialTransfer",
						FilterPath: "api/SalesTransfer/QuotationFilter"
					},
					"Transfer from Sales Order": {
						FullDocumentListing: "api/SalesTransfer/OrderFullDocument",
						PartialDocumentListing: "api/SalesTransfer/OrderPartial",
						FullDocumentTransfer: "api/SalesTransfer/OrderFullTransfer",
						PartialDocumentTransfer:  "api/SalesTransfer/OrderPartialTransfer",
						FilterPath: "api/SalesTransfer/OrderFilter"
					}
				}}
				transferData={transferFormData}
			/>
		</Container>
	);
}
