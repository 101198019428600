import React, { useState, useEffect, useRef, useCallback } from "react";
import PopupForm from "../../components/popup-form/PopupFormV2";
import baseapi from "../../api/baseapi";
import utils from "../../utils/common";
import { NumberBox } from "devextreme-react/number-box";
import { CheckBox } from "devextreme-react/check-box";
import { TextBox } from "devextreme-react/text-box";
import { loading, closeLoading } from "../../utils/common";
import { Validator, RequiredRule as ValidatorRequired } from "devextreme-react/validator";
import AsyncTextBox from "../../components/async-text-box/AsyncTextBox";

export default function SettingForm(props){
    const tempForm = useRef({});
	const formID = useRef(null);
	const formRead = useRef(false);
	const formRef = useRef(null);
	const [startValidation, setStartValidation] = useState(0);
	const [isEditing, setIsEditing] = useState(false);
	const [formValue, setFormValue] = useState({});

	useEffect(() => {
		// console.log("Form ID Changed", props.formID);
		if (props.formID !== null) {
			formID.current = props.formID;
			utils.popupFormOpen(formRef);

			if (formID.current !== "new") {
				setIsEditing(true);
			} else {
				setIsEditing(false);
			}
		}
	}, [props.formID]);


	function valueOnChange(e, receivedDataField = null) {
		// console.log("I am in value changed", e, "data field", e.element.getAttribute("dataField"))
		try {
			if (e.value !== undefined) {
				const dataField = receivedDataField !== null ? receivedDataField : e.element.getAttribute("dataField");
				const copied = { ...tempForm.current };
				copied[dataField] = e.value;
				tempForm.current = copied;
				setFormValue(tempForm.current);

			}
		} catch (err) {
			console.log(err);
		}
	}

	function handleSubmit({ stay }) {

		loading(`Saving ${props.title}...`);
		const submittedForm = utils.mergeObject(formValue, props.submittedValue);

		baseapi
			.httppost(
				utils.extendUrlVar(`${props.apiURL}/save`, {
					id: formID.current || "",
					del: false,
				}),
				submittedForm
			)
			.then((response) => {
				const { data } = response;

				console.log("resposne",data)
		
				utils.popupFormSuccessfulSubmit(formRef, data);
				utils.popupFormSetErrorForm(formRef, {
					visible: true,
					message: data.message,
					type: data.status ? "Success" : "Warning",
					action: data.action
				});
			});
	}
	function onInitialized(e) {
		if (formID.current === "new") loading(`Loading New ${props.title}...`);
		else loading(`Loading Existing ${props.title}...`);

		baseapi.httpget(`${props.apiURL}/Get?id=` + (formID.current || "new")).then((response) => {
			const { data } = response;
            const item = data.items;
            const model = data.model;
			
            if(!utils.isNullOrEmpty(item) && !utils.isNullOrEmpty(item[0])){
                tempForm.current = item[0];
            }
            else if(!utils.isNullOrEmpty(model)){
                tempForm.current = model;
            }

            setFormValue(tempForm.current);
			closeLoading();
			formRead.current = true;
		});
	}

	const clearForm = () => {
		props.closePopup(null);
		formRead.current = false;
		tempForm.current = {};
		setFormValue(tempForm.current);
	};

	useEffect(() => {
		if(!utils.isNullOrEmpty(formValue) && !utils.isNullOrEmpty(props.onValueChanged)){
			props.onValueChanged(formValue);
		}
	}, [formValue]);

	const dynamicFieldRendered = {
		asyncTextbox: (prop) => renderAsyncTextBox(prop),
		textbox: (prop) => renderTextBox(prop),
		numberbox: (prop) => renderNumberBox(prop),
		checkbox: (prop) => renderCheckBox(prop)
	};

	const renderAsyncTextBox = (detail) => {
		const dataField = utils.isNullOrEmpty(detail.dataField) ? crypto.randomUUID() + "TextBox" : detail.dataField;
		const caption = utils.isNullOrEmpty(detail.caption) ? dataField : detail.caption;
		const required = detail.required;
		const asyncProp = detail.asyncProperty;

		return <div className='popup-group-form-item maintanace-module-padding-bottom'>
			<div className='popup-group-form-label'>{caption} : </div>

			<div className='popup-group-form-input'>
				<AsyncTextBox
					value={formValue[dataField]}
					elementAttr={{ dataField: dataField }}
					onValueChanged={(e) => {
						valueOnChange(e);
					}}
					validationLength={10}
					alignment='left'
					width={"250px"}
					startValidation={startValidation !== 0}
					asyncURL={`${props.apiURL}/CheckDuplicateCode`}
					requiredMessage={`${dataField} is required!`}
					asyncMessage={`${dataField} is already taken!`}
					asyncProperty={asyncProp}
					asyncArgs={{ id: formID.current }}
					required={required}
					{...detail.config}
				/>
			</div>		
		</div>;
	};

	const renderTextBox = (detail) => {
		const dataField = utils.isNullOrEmpty(detail.dataField) ? crypto.randomUUID() + "TextBox" : detail.dataField;
		const caption = utils.isNullOrEmpty(detail.caption) ? dataField : detail.caption;
		const required = detail.required;

		return <div className='popup-group-form-item maintanace-module-padding-bottom'  >
			<div className='popup-group-form-label'>{caption} : </div>

			<div className='popup-group-form-input'>
				<TextBox
					value={formValue[dataField]}
					elementAttr={{ dataField: dataField }}
					onValueChanged={(e) => {
						valueOnChange(e);
					}}
					alignment='left'
					width={"auto"}
					{...detail.config}
				>
					<Validator>{(startValidation !== 0 && required === true) && <ValidatorRequired message={`${dataField} is required!`} />}</Validator>
				</TextBox>
			</div>		
		</div>;
	};

	const renderNumberBox = (detail) => {
		const dataField = utils.isNullOrEmpty(detail.dataField) ? crypto.randomUUID() + "NumberBox" : detail.dataField;
		const caption = utils.isNullOrEmpty(detail.caption) ? dataField : detail.caption;
		const required = detail.required;

		return <div className='popup-group-form-item maintanace-module-padding-bottom'>
			<div className='popup-group-form-label'>{caption} : </div>

			<div className='popup-group-form-input'>
				<NumberBox
					value={formValue[dataField]}
					elementAttr={{ dataField: dataField }}
					onValueChanged={(e) => {
						valueOnChange(e);
					}}
					alignment='left'
					width={"250px"}
					{...detail.config}
				>
					<Validator>{(startValidation !== 0 && required === true) && <ValidatorRequired message={`${dataField} is required!`} />}</Validator>
				</NumberBox>
			</div>		
		</div>;
	};

	const renderCheckBox = (detail) => {
		const dataField = utils.isNullOrEmpty(detail.dataField) ? crypto.randomUUID() + "CheckBox" : detail.dataField;
		const caption = utils.isNullOrEmpty(detail.caption) ? dataField : detail.caption;
		const required = detail.required;

		return <div className='popup-group-form-item maintanace-module-padding-bottom'>
			<div className='popup-group-form-label' style={{padding: "0px"}}>{caption} : </div>

			<div className='popup-group-form-input'>
				<CheckBox
					value={formValue[dataField]}
					elementAttr={{ dataField: dataField }}
					onValueChanged={(e) => {
						valueOnChange(e);
					}}
					alignment='left'
					width={"auto"}
					{...detail.config}
				>
					<Validator>{(startValidation !== 0 && required === true) && <ValidatorRequired message={`${dataField} is required!`} />}</Validator>
				</CheckBox>
			</div>		
		</div>;
	};

	return (
		<div>
			<PopupForm
				ref={formRef}
				onClosing={clearForm}
				fullScreen={false}
				width={"50%"}
				height={"auto"}
				headerClassName={"maintainance-module-form-title-bg popup-form-title"}
				creditChecking={false}
				apiURL={props.apiURL}
				title={props.title}
				onShown={onInitialized}
				onSuccessfulSubmit={({ stay }) => { if (stay) onInitialized() }}
				onPasteForm={(e) => setFormValue(e.Parent)}
				//copyStorage={"APDebitNoteCopiedData"}
				formData={formValue}
				copyStorage={"CreditTermsCopiedData"}
				onValidating={setStartValidation}
				startValidation={startValidation}
				isEditing={isEditing}
				onSaving={handleSubmit}
				actionButtonEnabled = {false}
                saveButtonOnly={true}
				cancelDocumentCustomization={
					<span className='popup-form-header-cancel'>
						<CheckBox
							value={formValue["Active"]}
							elementAttr={{ dataField: "Active" }}
							onValueChanged={(e) => {
								valueOnChange(e);
							}}
							alignment='left'
							width={"25px"}
							height={"50px"}
							iconSize={"18px"}
							disabled={props.lockedForm === true}
							className='popup-header-cancel-input'
						/>

						<span className='popup-header-cancel-text' style={{ color: '#808080' }}>Active</span>

					</span>
				}>	<hr className='maintenance-popup-form-hr' />
				<div className='popup-form-main-content'>
                    {
                        (utils.isNullOrEmpty(props.customizedFormRender) && props.defaultSettingTemplate !== false) && <div>
                            <div className='popup-group-form-item maintanace-module-padding-bottom '>
								<div className='popup-group-form-label'>Code : </div>

								<div className='popup-group-form-input' >
									<AsyncTextBox
										value={formValue["Code"]}
										elementAttr={{ dataField: "Code" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										validationLength={10}
										alignment='left'
										width={"250px"}
										startValidation={startValidation !== 0}
										asyncURL={`${props.apiURL}/CheckDuplicateCode`}
										requiredMessage={"Code is required!"}
										asyncMessage={"This Code is already taken!"}
										asyncProperty={"code"}
										asyncArgs={{ id: formID.current }}
										required={true}
									/>
								</div>
							</div>
						
                            <div className='popup-group-form-item maintanace-module-padding-bottom '>
                                <div className='popup-group-form-label'>Description : </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={formValue["Name"]}
                                        elementAttr={{ dataField: "Name" }}
                                        onValueChanged={(e) => {
                                            valueOnChange(e);
                                        }}
                                        alignment='left'
                                        width={"auto"}
                                        disabled={props.lockedForm === true}>
                                        <Validator>{startValidation !== 0 && <ValidatorRequired message={"Description is required!"} />}</Validator>
                                    </TextBox>
                                </div>
                            </div>

                            <div className='popup-group-form-item maintanace-module-padding-bottom '>
								<div className='popup-group-form-label' style={{padding: 0}}>Set As Default : </div>

								<div className='popup-group-form-input'>
									<CheckBox
										value={formValue["IsDefault"]}
										elementAttr={{ dataField: "IsDefault" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										disabled={props.lockedForm === true}
									>
									</CheckBox>
								</div>
							</div>
                        </div>
                    }
					
                    {
                        Array.isArray(props.customizedFormRender) && props.customizedFormRender.map((field) => {
							if(!utils.isNullOrEmpty(field.type)){
								return dynamicFieldRendered[field.type](field)
							}
						})
                    }
				</div>
			</PopupForm>
		</div>
	);
}