import React, { useEffect,  useState } from "react";
import { Column } from "devextreme-react/data-grid";
import Container from "../../../components/container";
import Listing from "../../../components/listing/newListing";
import EInvoiceDownload from "../../../components/e-invoice/EInvoiceDownload";

export default function EInvoiceListing() {
    const [currentDownloadID, setCurrentDownloadID] = useState(null);
	const [transferData, setTransferData] = useState(null);


	const apiURL = `/api/PurchaseCashPurchase`;

	function handleDownload(e) {
        setCurrentDownloadID(e.row.data.uuid);
	}

	const closePopup = (value) => {
		setTransferData(null);
        setCurrentDownloadID(value);
	};

	useEffect(() => {
	
	
	}, []);

	return (
		<Container>
			<div id='normal-listing'>
				<Listing
				addShow={true}
					      apiURL={apiURL}
            listingURL="/api/EInvoice/list"
			downloadShow={true}
            handleDownload={
                handleDownload
            }
					sortColumn={"internalId"}
					
				>
					<Column dataField='internalId' caption={'Document No'} />
					<Column dataField='dateTimeReceived' caption={'Document Date'} format='dd/MM/yyyy' dataType={"date"}/>

					<Column dataField='typeName' caption={'Document Type'} />

					<Column dataField='status' caption={'Document Status'} />

					<Column dataField='supplierTIN' caption={'Supplier TIN'} />

					<Column dataField='supplierName' caption={'Supplier Name'} />

					<Column dataField='buyerTIN' caption={"Buyer TIN"} />

					<Column dataField='buyerName' caption={"Buyer Name"} />
				</Listing>
			</div>


		<EInvoiceDownload
				formID={currentDownloadID} 
				closePopup={closePopup} 
				apiURL={apiURL} 
				title={"E-Invoice Main"} 
				transferData={transferData}
				clearTransfer={setTransferData}
		/>
		


		
		</Container>
	);
}